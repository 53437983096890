import React, { useState, useEffect } from 'react';
import { Stack } from '@carbon/react';
import { Modal, Form, TextInput, TextArea, Button, Loading, ContainedList, ContainedListItem } from 'carbon-components-react';
import { TrashCan as Delete, Add } from '@carbon/icons-react';
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../../../redux/Common/CommonReducer';
import useToastAlert from '../../../../hooks/useToastAlert'
import helpers from '../../../../_helpers/common'
import { __trans, makeRequest, getData } from '../../../../_helpers'

const CreateUpdateDocumentTemplate = ({ open, setOpen, id }) => {
	const { addError } = useToastAlert();
	const { contentLoading } = useSelector((state) => state.common);
    const dispatch = useDispatch();
	const [document_name, setDocumentName] = useState('');
	const [document_name_error, setDocumentNameError] = useState('');
	const [optionrows, setOptionRows] = useState([]);
	const [selectedoption, setSelectedOption] = useState([]);
	const [submitcontrol, setSubmitControle] = useState({ status: 'inactive', description: __trans('submit_button') });
	const [form, setForm] = useState({ name: '',  name_error: '', description: '' });
	
	const resetSubmitControlStatus = () => {
		setSubmitControle({...submitcontrol, status: 'inactive', description: __trans('submit_button')})
	};
	 
	const getDocumentData = async () => {
		await getData("template/get-template-documents/"+id, {}, 'POST', addError, dispatch)
		.then((res) => {
			if (res.type === "success") {
				setForm({ ...form, type: res.template.type, name: res.template.name, description: res.template.description });
				
				var current_document = res.documents[0].documents;
				setOptionRows(current_document);
				var current_selection = [];
				for (const item of current_document) {
					current_selection.push(item._id)
				}
				setSelectedOption(current_selection)				
			} 
		})
		.catch((error) => {

		});
	}
	  
	useEffect(() => {
		if(open === true){
			if(id !== '0'){
				getDocumentData();
			}
		}
	}, [open])
	
	const validateDocumentName = () => {
		let validateData = true;
		if(helpers.isEmpty(document_name)){
			validateData = false;
			setDocumentNameError(__trans('error_msg.field_required_error'));
		}else{
			setDocumentNameError('');
		}
		return validateData;
	}
	
	const saveDocument = async () => {
		if(validateDocumentName()){
			await makeRequest('document/create', { name: document_name.trim(), description: '' }, 'POST', addError)
				.then((res) => {
					if (res.type === "success") {
						setDocumentName('');
						setOptionRows([ ...optionrows, res.data ]);
						setSelectedOption([ ...selectedoption, res.data._id ]);
					} 
				}).catch((error) => {
					
				});
		}
	}
	
	const valueChanged = (e) => {
		const { name, value } = e.target;
		let newEdit = { ...form };
		newEdit[name] = value;
		newEdit[name + '_error'] = '';
		setForm(newEdit);
	}
	
	const validateForm = () => {
		let newEdit = { ...form };
		let validateData = true;
		
		if (helpers.isEmpty(form.name)) {		
			newEdit.name_error = __trans('error_msg.template_name_error');
			validateData = false;
		} else {
			newEdit.name_error = '';
		}
		
		setForm(newEdit);
		return validateData;
	}
	
	const handleSubmit = (e) => {
		if (validateForm()) {
			submitForm({ type: 2, name: form.name, description: form.description, options: selectedoption })
		}
	}

	const submitForm = async (formData) => {
		setSubmitControle({...submitcontrol, status: 'active'})
		var submit_url = id === '0' ? 'template/create' : 'template/update/' + id;
		await makeRequest(submit_url, formData, 'POST', addError)
			.then((res) => {
				setSubmitControle({...submitcontrol, status: 'finished'})
				if (res.type === "success") {
					dispatch(callCommonAction({ reloaddata: true }));
					handleCancel();
				}
			}).catch((error) => {
				setSubmitControle({...submitcontrol, status: 'finished'})
			});
	}
	
	const deleteItemButton = (Id) => {
		return  (<Button kind="ghost" iconDescription={__trans('delete_button')} onClick={() => deleteSelectedItem(Id)} hasIconOnly renderIcon={Delete} aria-label={__trans('delete_button')} />);
	}
	
	const deleteSelectedItem = async(id) => {
		//console.log('deleteSelectedItem function called')
		/*await makeRequest('document/delete', {item_id: id}, 'POST', addError)
			.then((res) => {
				if (res.type === "success") {
					//update the selected option
					const itemArray = selectedoption.filter((item, index) => item !== id);
					setSelectedOption(itemArray);
					//update items
					const optionArray = optionrows.filter((item, index) => item._id !== id);
					setOptionRows(optionArray);
				} 
			}).catch((error) => {
				
			});*/
		//update the selected option
		const itemArray = selectedoption.filter((item, index) => item !== id);
		setSelectedOption(itemArray);
		//update items
		const optionArray = optionrows.filter((item, index) => item._id !== id);
		setOptionRows(optionArray);	
	}
	
	const handleCancel = () => {
		setForm({ type: '',  type_error: '', name: '',  name_error: '', type_option: [], is_visible: false, is_required: false, sort: 0,});
		setOptionRows([]);
		setSelectedOption([]);
		setOpen(false)
	}
	
    return (
        <Modal className="header-border two-buttons" open={open} onRequestClose={() => handleCancel()} 
			modalHeading={__trans('document_request_template')} 
			modalLabel={id === '0' ? __trans('add_template_text') : __trans('edit_template_text')}
			preventCloseOnClickOutside={true}
			primaryButtonText={__trans('submit_button')} 
			secondaryButtonText={__trans('cancel_button')} 
			onRequestSubmit={() => handleSubmit() }
			loadingStatus={submitcontrol.status} 
			loadingDescription={submitcontrol.description} 
			onLoadingSuccess={resetSubmitControlStatus}
		>
		{!contentLoading ?
			<Form aria-label="create/update template form" className="">
				<Stack gap={5}>
					<TextInput type="text" name="name" id="template_name" 
						value={form.name}
						onChange={valueChanged} 
						labelText={__trans('forms.template_name')} 
						placeholder={__trans('forms.template_name_placeholder')} 
						invalid={form.name_error !== '' ? true : false}
						invalidText={form.name_error}
					/>
					<div className="row align-items-end">
					<div className="col-6">
					<ContainedList className="bg-body border p-3" label={__trans('forms.document_request_list')} kind="on-page" size="sm" action={''}>
						{optionrows.length > 0 && optionrows.map((item) => (
						  <ContainedListItem action={deleteItemButton(item._id)}>{item.name}</ContainedListItem>
						))}
					</ContainedList>
					</div>
						<div className="col-6">
							<TextArea id="document_description" name="description"
								maxCount={100}
								value={form.description}
								onChange={valueChanged} 
								labelText={__trans('forms.template_description')}
								placeholder={__trans('forms.template_description_placeholder')}
								rows={4}
							/>
						</div>
					</div>
					
					<div className="row">
					<div className="col-md-6">
						<TextInput type="text" name="document_name" id="document_name" 
							value={document_name}
							onChange={(e) => setDocumentName(e.target.value)} 
							labelText={__trans('forms.document_name')} 
							placeholder={__trans('forms.document_name_placeholder')} 
							invalid={document_name_error !== '' ? true : false}
							invalidText={document_name_error}
						/>
						</div>
						<div className="col-md-2 mt-4">
							<Button size='md' hasIconOnly renderIcon={Add} iconDescription="Add" onClick={() => saveDocument()} />
						</div>
					</div>
					
				</Stack>
			</Form>
			
		:
			<div className='d-flex justify-content-center'>
				<Loading className='text-center' withOverlay={false} />
			</div>
		}
		</Modal>
    );
};
export default CreateUpdateDocumentTemplate