import { ToastNotification } from 'carbon-components-react';
import useToastAlert from '../../hooks/useToastAlert'

const Notification = () => {
	const { error, removeError } = useToastAlert();
	//console.log('error', error)
	//const type = 'error';
	//const text = 'This is test message';
	return (
		<>
		{error && error.message !== '' && error.status !== '' ?
			<ToastNotification 
				role="status" 
				style={{ position: 'fixed', zIndex: 9999 }}
				kind={error.status} 
				lowContrast={true} 
				timeout={5000} 
				title="" 
				subtitle={error.message}
				onClose={() => removeError()}
			 />
		: ''
		}
		</>	
	);
};

export default Notification;
