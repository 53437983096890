import React from 'react';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { InlineLoading } from 'carbon-components-react';
import { useDispatch } from 'react-redux';
import { callCommonAction } from '../../../../../redux/Common/CommonReducer';
import useToastAlert from '../../../../../hooks/useToastAlert'
import { makeRequest } from '../../../../../_helpers';

const PayPalButton = ({ firmID, itemprice, unit_price, quantity, setOpen }) => {
	const { addError }    = useToastAlert();
	 const dispatch = useDispatch();
	const [{ isPending }] = usePayPalScriptReducer();
	
	const createOrder = () => {
		//console.log('create order function called')
		return makeRequest("buy-request-order/create-order", {firm_id: firmID, quantity: quantity, price: itemprice, unit_amount: unit_price}, 'POST', addError)
		  .then((res) => {
			  //console.log('order response', res)
			if (res.type === "success") {
				return res.data.order_id;
			} 
		  }).catch((error) => {
				//console.log('error', error)
		  });
	}
	
	const onApprove = (data) => {
		return  makeRequest("buy-request-order/capture-paypal-order", {payment_source: data.paymentSource, orderID: data.orderID}, 'POST', addError)
		  .then((res) => {
			if (res.type === "success") {
				localStorage.removeItem('firm');
				localStorage.setItem('firm', JSON.stringify(res.firm));
				dispatch(callCommonAction({ reloaddata: true }));
				setOpen(false);
			} 
		  }).catch((error) => {
				
		  });
	}
  return (
	<>
		{isPending ? <InlineLoading className="d-flex justify-content-center" status="active" iconDescription="Loading" description="Loading ..." /> : null}
		<PayPalButtons createOrder={createOrder} onApprove={onApprove}/>
	</>
  )
}

export default PayPalButton