import React, { useState, useEffect } from 'react';
import { Tile, Tag, Button } from '@carbon/react';
import { View, Add } from '@carbon/icons-react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import useToastAlert from '../../../hooks/useToastAlert'
import { __trans, makeRequest, userDetails } from '../../../_helpers'
import BuyRequest from './purchase-request/BuyRequest';
import RequestBuyPayment from './purchase-request/RequestBuyPayment';

const Setting = () => {
	const { addError } = useToastAlert();
	const { reloaddata } = useSelector((state) => state.common);
	const navigate = useNavigate();
	const [buyrequest, setBuyRequest] = useState(false);
	const [requestpayment, setRequestPayment] = useState(false);
	const [settingdata, setSettingData] = useState({request_count: 0});
	const [buyform, setBuyForm] = useState({ request_purchase: 50, price: '0', unit_price: '0', request_purchase_error: '', buy_now: false });
	
	const getData = async () => {
		//var user_id = userDetails('id');
		try {
			const res = await makeRequest(`users/detail/${userDetails('id')}`, {},  'POST', addError);
			if (res.data !== null && res.data.firm_id) {
				setSettingData({ ...settingdata, request_count: res.data.firm_id ? res.data.firm_id.available_request : 0});
			}
		} 
		catch (error) {
			//addError(error.message, 'error')
		}
	};
  
	useEffect(() => {
		getData();
	}, [])
	
	useEffect(() => {
		getData();
	}, [reloaddata])

	return (
		<>
			<div className="page-heading">
				<h1>{__trans('firm_settings')}</h1>
			</div>
			<div className="row border p-3">
				<div className="col-4">
					<Tile id="tile-1">
						<div className="d-flex justify-content-between">
							{__trans('setting_request_available')}
							<Tag className="" type='blue'>
								{settingdata.request_count}
							</Tag>
						</div>
						<br />
						<br />
						<div className="d-flex justify-content-between">
							<Button kind='ghost' size='sm' 
								iconDescription="Icon Description" 
								renderIcon={View} 
								onClick={() => navigate('/request-purchase-list')}
							>
								{__trans('view_histroy_button')}
							</Button>
							<Button kind='ghost' size='sm' 
								iconDescription="Icon Description" 
								renderIcon={Add} 
								onClick={() => setBuyRequest(true)}
							>
								{__trans('setting_buy_request')}
							</Button>
						</div>
					</Tile>	
				</div>
			</div>
			{/* Buy Request popup */}
			<BuyRequest open={buyrequest} setOpen={setBuyRequest} setPaymentOpen={setRequestPayment} buyform={buyform} setBuyForm={setBuyForm}/>
			{buyform.buy_now ?
				<RequestBuyPayment open={requestpayment} setOpen={setRequestPayment} buyform={buyform}/>
			:''
			}			
	  </>
	)
}

export default Setting
