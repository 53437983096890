import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Stack } from '@carbon/react';
import {Modal, FileUploaderButton, FileUploaderItem,  Loading } from 'carbon-components-react';
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import useToastAlert from '../../../hooks/useToastAlert'
import { __trans, makeRequest } from '../../../_helpers'

var lastId = 0;

const UploadDocument = ({ open, setOpen, id, form, setForm, setNextStep, handleDelete }) => {
	const { addError } = useToastAlert();
	const navigate = useNavigate();
	const { contentLoading, reloaddata } = useSelector((state) => state.common);
	const dispatch = useDispatch();
	const [submitcontrol, setSubmitControle] = useState({ status: 'inactive', description: __trans('submit_button') });
	const [previewfiles, setPreviewFiles] = useState([]);
	const [uploaddata, setUploadData] = useState([]);
	const [serverdata, setServerData] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');
	
	const resetSubmitControlStatus = () => {
		setSubmitControle({...submitcontrol, status: 'inactive', description: __trans('submit_button')})
	};
	
	const getFormData = async () => {
		 if(form.client_request_id !== '' && form.sign_document === 1){
			dispatch(callCommonAction({ contentLoading: true }));
			await makeRequest("firm/get-firm-client-contract", {client_id: form.client_id, client_request_id: form.client_request_id}, 'POST', addError)
				.then((res) => {
					dispatch(callCommonAction({ contentLoading: false }));
					if (res.type === "success") {
						setServerData(res.data.docs);
					} 
				})
				.catch((error) => {
					dispatch(callCommonAction({ contentLoading: false }));
				});
		 }
	  }
	  
	useEffect(() => {
		if(open){
			if(form.client_request_id !== ''){
				getFormData();
			}
		}
	}, [open])
	
	useEffect(() => {
		if(form.client_request_id !== ''){
			getFormData();
		}
	}, [reloaddata])
		
	const uid = (prefix = 'id') => {
	  lastId++;
	  return `${prefix}${lastId}`;
	}
	
	const handleFileChange = async(event) => {
		//console.log('handleFileChange', event)
		const files_object = event.target.files;
		var has_error = true;
		var new_upload_data = [...uploaddata];
		var new_files       = [...previewfiles];
		for (const file of files_object) {
			if (file.size > 5 * 1000 * 1024) {
				console.log("File with maximum size of 5MB is allowed");
				continue;
			}
			
			if(!isValidFileUploaded(file)){
				console.log("File Type not allowed");
				continue;
			}
			//set upload data
			new_upload_data.push(file);
			//set preview data
			new_files.push({ uuid: uid(), name: file.name, filesize: file.size, status: 'edit', iconDescription: 'Delete file',
				invalidFileType: file.invalidFileType });
			
		}
		setErrorMessage('');
		setUploadData(new_upload_data)
		setPreviewFiles(new_files);
	};
	
	const isValidFileUploaded=(file)=>{
		const validExtensions = ['png', 'jpg', 'jpeg', 'csv', 'xlsx', 'pdf', 'doc', 'docx'];
		//const fileExtension = file.type.split('/')[1];
		const fileExtension = file.name.split('.').pop();
		//console.log('fileExtension', fileExtension)
		return validExtensions.includes(fileExtension);
	}
	
	const validateForm = () => {
		let newEdit = { ...form };
		let validateData = true;
		if (uploaddata.length === 0) {
			setErrorMessage(__trans('error_msg.select_valid_file_error'));
			validateData = false;
		} else {
			setErrorMessage('');
		}
		
		setForm(newEdit);
		return validateData;
	}
	
	const handleSubmit = (e) => {
		//Before submit validate form
		if(form.client_request_id !== ''){
			if(form.sign_document === 1){
				if(serverdata.length > 0){
					setNextStep(4);
				}
			}else{
				setNextStep(5);
			}
		}
		if(form.sign_document === 1){
			if (validateForm()) {
				submitForm('next')
			}
		}else{
			setNextStep(5);
		}	
	}
	
	const saveAndClose = async() => {
		if(form.client_request_id !== ''){
			if(serverdata.length > 0){
				navigate('/clients/in-progress-list');
			}
		}
		if(form.sign_document === 1){
			if (validateForm()) {
				submitForm('save_close')
			}
		}else{
			setNextStep(4);
		}	
	}

	const submitForm = async (submit_type) => {
		console.log('submit_type', submit_type);
		//setNextStep(4);
		if (validateForm()) {
			var formData = new FormData();
			//console.log('uploaddata data', uploaddata)
			if(uploaddata.length > 0){
				 uploaddata.forEach(item => {	formData.append(`contract_file`, item) });
			}
			formData.append(`package_id`, form.package_id)
			formData.append(`client_id`, form.client_id)
			formData.append(`sign_document`, form.sign_document)
			formData.append(`question_template_id`, form.question_template_id)
			formData.append(`documents_template_id`, form.documents_template_id)
			
			setSubmitControle({...submitcontrol, status: 'active'})
			let end_url = form.client_request_id === '' ? 'firm/create-client-request' : 'firm/update-client-request/'+form.client_request_id;
			await makeRequest(end_url, formData, 'POST', addError)
				.then((res) => {
					setSubmitControle({...submitcontrol, status: 'finished'})
					if (res.type === "success") {
						if(submit_type === 'save_close'){
							navigate('/clients/in-progress-list');
						}else{
							setForm({...form, client_request_id: res.data._id });
							setNextStep(4);
						}
					} 
				})
				.catch((error) => {
					setSubmitControle({...submitcontrol, status: 'finished'})
				});
		}
	}
	  
	
	const handleRemoveFile = (index) => {
		//console.log('handelRemoveFile function called', index)
		// Remove the file from the upload files array
		const uploadFiles = [...uploaddata];
		uploadFiles.splice(index, 1);
		setUploadData(uploadFiles);
		// Remove the file from the preview files array
		const previewFiles = [...previewfiles];
		previewFiles.splice(index, 1);
		setPreviewFiles(previewFiles);
	};
	
	const handleCancel = () => {
		navigate('/clients/in-progress-list');
	}
	
	//console.log('serverdata', serverdata)
    return (
        <Modal className="header-border two-buttons arrow-icon" size="md" open={open} onRequestClose={() => handleCancel()} 
			modalHeading={__trans('upload_document_page_heading')} 
			modalLabel=""
			preventCloseOnClickOutside={true}
			primaryButtonText={__trans('continue_button')} 
			secondaryButtonText={__trans('save_and_close_button')} 
			onSecondarySubmit={() => saveAndClose()}
			onRequestSubmit={() => handleSubmit() }
			loadingStatus={submitcontrol.status} 
			loadingDescription={submitcontrol.description} 
			onLoadingSuccess={resetSubmitControlStatus}
		>
		{form.sign_document === 1 ?
			<>
			{!contentLoading ? 
				<Stack>
					<p className="cds--label-description fs-16 text-16">
						{__trans('upload_document_page_info')}
					</p>
					<h3 className="cds--file--label" dir="auto">{__trans('forms.upload_document_label')}</h3>
					<p className="cds--label-description" id="description" dir="auto">{__trans('upload_contract_file_info')}</p>
					  <div className="cds--file__container mb-1">
						  <FileUploaderButton className="upload-icon" 
							labelText={__trans('upload_button')}
							disableLabelChanges={true}
							buttonKind="primary" 
							size="md"
							accept={['.pdf']} 
							multiple={true}
							onChange={handleFileChange}
						/>
						{errorMessage && <><br/><span className="text-danger mt-2">{errorMessage}</span></>}
					  </div>
					  <div className="cds--file-container cds--file-container--drop fullWidth">
						{previewfiles.length > 0 ? <label className="cds--label mt-2">{__trans('forms.new_selected_files_label')}</label> : ''}
							{previewfiles.length > 0 && previewfiles.map((file, index) => (
							  <FileUploaderItem
								key={uid()}
								uuid={file.uuid}
								name={file.name}
								filesize={file.filesize}
								errorSubject={__trans('error_msg.file_size_limit_error')}
								errorBody=""
								// eslint-disable-next-line react/prop-types
								size="md"
								status={file.status}
								iconDescription={file.iconDescription}
								invalid={file.invalid}
								onDelete={() => handleRemoveFile(index)}
							  />
							))}
							{serverdata.length > 0 ? <label className="cds--label mt-2">{__trans('forms.server_files_label')}</label> : ''}
							{serverdata.length > 0 && serverdata.map((file, index) => (
							  <FileUploaderItem
								key={uid()}
								name={file.file_original_name}
								filesize={file.size}
								errorSubject={__trans('error_msg.file_size_limit_error')}
								errorBody=""
								// eslint-disable-next-line react/prop-types
								size="md"
								status="edit"
								iconDescription='Delete file'
								invalid={false}
								onDelete={() => handleDelete(file._id, 'contract_file')}
							  />
							))}
							
						  </div>
				</Stack>
			:
				<div className='d-flex justify-content-center'>
					<Loading className='text-center' withOverlay={false} />
				</div>
			}
			</>
		:
			<div className='text-center my-5'>
				<h5>{__trans('no_document_sign_required')}</h5>
			</div>
		}
		
      </Modal>
    );
};

export default UploadDocument;
