import { DataTableSkeleton } from 'carbon-components-react';

const TableLoader = ({rows_count, colomn_count, headers}) => {
  
  return (
	<DataTableSkeleton rowCount={Number(rows_count)} columnCount={Number(colomn_count)} aria-label="sample table" />
  );
};

export default TableLoader;
