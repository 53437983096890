import React, { useCallback, useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { TableContainer, Table, TableHead, TableHeader, TableBody, TableRow, TableCell, TableSelectRow, 
        TableSelectAll, TableToolbar, TableToolbarContent, TableToolbarSearch, Pagination, ButtonSet, Button} from '@carbon/react';
import { TrashCan as Delete, Edit, Add, DocumentImport, Export, Column } from '@carbon/icons-react';		
import { useRowSelection, useSortInfo, useUniqueId } from '../../../_helpers/tables/table_hooks';
import DeleteClientConfirmation from './DeleteClientConfirmation';
import useToastAlert from '../../../hooks/useToastAlert';
import TableLoader from '../../common/TableLoader';
import TableNoData from '../../common/TableNoData';
import { useSelector, useDispatch } from 'react-redux';
import { TABLE_SIZE, TABLE_SORT_DIRECTION } from '../../../_helpers/tables/table_misc';
import { Header as headers } from './Header';
import helpers from '../../../_helpers/common';
import { __trans, getData, makeRequest } from '../../../_helpers';
import CreateUpdateClient from './create-update/CreateUpdateClient'
import UploadClientCSV from './create-update/UploadClientCSV'


const hasSelection = true;


const List = () => {
	const { addError } = useToastAlert();
	const dispatch     = useDispatch();
	const [searchParams, setSearchParams]    = useSearchParams();
	const { contentLoading, reloaddata }     = useSelector((state) => state.common);
	const [createclient, setCreateClient]    = useState(false);
	const [uploadclient, setUploadClient]    = useState(false);
	const [deleteitem, setDeleteItem]        = useState(false);
	const [selecteditem, setSelectedItem]    = useState('');
	const [selectedname, setSelectedName]    = useState('');
	const [searchString, setSearchString]    = useState('');
	const [rows, setRows]                    = useState([]);
	const [pagination, setPagination]        = useState({page : 1, limit: 15, total_pages: 0, total_item: 0});
	const [sortInfo, setSortInfo]            = useSortInfo({ columnId: '_id', direction: TABLE_SORT_DIRECTION.DESCENDING });
	const [setRowSelection] 				 = useRowSelection(rows, searchString, setRows);
	const selectedRowsCount                  = rows.filter(({ selected }) => selected).length;
	const selectedAll                        = selectedRowsCount > 0 && rows.length === selectedRowsCount;
	const hasBatchActions                    = hasSelection && selectedRowsCount > 0;
	const elementId                          = useUniqueId('archived_list');
	const selectionAllName                   = !hasSelection ? undefined : `__custom-data-table_select-all_${elementId}`;
	const { columnId: sortColumnId, direction: sortDirection } = sortInfo;
	
	const getFirmClientList = async(type) => {
		var res = {};
		if(type === 'load'){
			res = await getData("users/firm-client-list-data", {page: pagination.page, limit: pagination.limit, search_string: searchString}, 'POST', addError, dispatch)
		}else{
			res = await makeRequest("users/firm-client-list-data", {page: pagination.page, limit: pagination.limit, search_string: searchString}, 'POST', addError);
		}
		
		if (res.type === "success") {
			setRows(res.data.docs);
			setPagination({...pagination, total_pages: Number(res.data.totalPages), total_item: Number(res.data.totalDocs)});
		}	
	}
  
	const handleGetData = useCallback(() => {
		getFirmClientList('load');
	}, []);
  
	useEffect(() => {
		getFirmClientList('load');
		let type = searchParams.get("type");
		let item_id = searchParams.get("id");
		if(type !== null && item_id !== null){
			handleAddEditClient(item_id);
		}
	}, [])
  
	useEffect(() => {
		if(reloaddata){
			handleGetData();
			clearQueryParams();
		}
	}, [reloaddata])
	
	useEffect(() => {
		getFirmClientList('filter');
	}, [pagination.page, pagination.limit, searchString])
  
	const clearQueryParams = () => {
		if (searchParams.has('type')) {
			searchParams.delete('type');
			setSearchParams(searchParams);
		}
		if (searchParams.has('id')) {
			searchParams.delete('id');
			setSearchParams(searchParams);
		}
	}
  
	const handleDeleteItem = (row) => {
		setSelectedItem(row._id);
		setSelectedName(row.fullname);
		setDeleteItem(true);
	}
	
	const handleAddEditClient = (id) => {
		setCreateClient(true);
		setSelectedItem(id);
	}

	const handleBulkUploadClient = () => {
		setUploadClient(true);
	}

	const handleChangeSearchString = useCallback(({ target }) => {
		setSearchString(target.value);
	}, [setSearchString]);

	const handleChangeSelection = useCallback((event) => {
		const { currentTarget } = event;
		const row = currentTarget.closest('tr');
		if (row) {
			setRowSelection(row.dataset.rowId, currentTarget.checked);
		}
	}, [setRowSelection]);

	const handleChangeSelectionAll = useCallback((event) => {
		setRowSelection(undefined, event.currentTarget.checked);
	}, [setRowSelection]);

	const handleChangeSort = useCallback((event) => {
		const { currentTarget } = event;
		const { columnId, sortCycle, sortDirection: oldDirection, } = currentTarget.dataset;
		setSortInfo({ columnId, sortCycle, oldDirection });
	}, [setSortInfo]);

	/* const handleDeleteRows = useCallback(() => {
		
	}, []); */
	
	const handlePaginationChange = (e) => {
		//console.log('pagination event', e)
		setPagination({...pagination, page: e.page, limit: e.pageSize})
	}
  
	const processTableCell = (columnId, row, index) =>{
		if(columnId === '_id'){
			return <TableCell key={columnId}>{index + 1}</TableCell>
		}else if(columnId === 'phone_no'){
			return <TableCell className="text-nowrap" key={columnId}>{row[columnId]}</TableCell>
		}else if(columnId === 'company'){
			return <TableCell className="text-nowrap" key={columnId}>{row['client_profile_id'] !== undefined && row['client_profile_id'] !== null ? row['client_profile_id'].company_name  : ''}</TableCell>
		}else if(columnId === 'cuil_cuit'){
			return <TableCell className="text-nowrap" key={columnId}>{row['client_profile_id'] !== undefined && row['client_profile_id'] !== null ? row['client_profile_id'].cuil_cuit  : ''}</TableCell> 
		}else{
			return <TableCell className="" key={columnId}>{row[columnId]}</TableCell>
		}
	}

  /* eslint-disable no-script-url */
  return (
    <>
	{!contentLoading ?
    <TableContainer title={__trans('client_list_title')} description={__trans('client_list_info')}>
      <TableToolbar size="lg">
        <TableToolbarContent className="align-items-center">
          <TableToolbarSearch
				value={searchString}
				defaultExpanded={searchString.length > 0 ?? true}
				tabIndex={hasBatchActions ? -1 : 0}
				onChange={handleChangeSearchString}
          />
		  <Button size="sm" kind="ghost" hasIconOnly renderIcon={Delete} iconDescription={__trans('delete_button')} onClick={() => alert('TableToolbarAction - Delete')} />
		  <Button size="sm" kind="ghost" hasIconOnly renderIcon={Export} iconDescription={__trans('export_button')} onClick={() => alert('TableToolbarAction - Upload')} />
		  <Button size="sm" kind="ghost" hasIconOnly renderIcon={Column} iconDescription={__trans('colomn_configration_button')} onClick={() => alert('Column')} />
		  <ButtonSet>
			  <Button className="gray-60 text-white" size="lg" onClick={() => handleBulkUploadClient()} kind="secondary" renderIcon={DocumentImport}>
				{__trans('add_client_csv_button')}
			  </Button>
			  <Button className="btn--primary " size="lg" onClick={() => handleAddEditClient('0')} kind="primary" renderIcon={Add}>
				{__trans('new_client_button')}
			  </Button>
		  </ButtonSet>
        </TableToolbarContent>
      </TableToolbar>
      <Table size="sm" isSortable useZebraStyles={true}>
        <TableHead>
          <TableRow>
            {hasSelection && (
              <TableSelectAll
                id={`${elementId}--select-all`}
                checked={selectedAll}
                indeterminate={
                  selectedRowsCount > 0 && !selectedAll
                }
                ariaLabel="Select all rows"
                name={selectionAllName}
                onSelect={handleChangeSelectionAll}
              />
            )}
            {headers.map(({ id: columnId, sortCycle, title }) => {
              const sortDirectionForThisCell = sortCycle && (columnId === sortColumnId ? sortDirection : TABLE_SORT_DIRECTION.NONE);
              return (
                <TableHeader
                  key={columnId}
                  isSortable={Boolean(sortCycle)}
                  isSortHeader={sortCycle && columnId === sortColumnId}
                  sortDirection={sortDirectionForThisCell}
                  data-column-id={columnId}
                  data-sort-cycle={sortCycle}
                  data-sort-direction={sortDirectionForThisCell}
                  onClick={handleChangeSort}>
                  {title}
                </TableHeader>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ?
			rows.map((row, index) => {
            const { id: rowId, selected } = row;
            const selectionName = !hasSelection ? undefined : `__custom-data-table_${elementId}_${rowId}`;
            return (
              <TableRow
				key={row._id}
                isSelected={hasSelection && selected}
                data-row-id={rowId}>
                {hasSelection && (
                  <TableSelectRow
                    id={`${elementId}--select-${rowId}`}
                    checked={Boolean(selected)}
                    name={selectionName}
                    ariaLabel="Select row"
                    onSelect={handleChangeSelection}
                  />
                )}
                {headers.map(({ id: columnId }) => (
                  <>
                    {columnId !== 'actions' ?
                      <>
                      {processTableCell(columnId, row, index)}
                      </>
                      :
						<TableCell key={columnId}>
							<div className="d-flex align-items-center">
								<Button size="sm" kind="ghost" renderIcon={Edit} onClick={() => handleAddEditClient(row._id)} iconDescription={__trans('edit_button')} hasIconOnly />
								<Button size="sm" kind="ghost" renderIcon={Delete} onClick={() => handleDeleteItem(row)} iconDescription={__trans('delete_button')} hasIconOnly />
							</div>
						</TableCell>
                    }
                  </>
                ))}
              </TableRow>
            );
          })
		  :
			<TableNoData headers={headers}/>
		  }
        </TableBody>
      </Table>
		<Pagination
		  backwardText="Previous page"
		  forwardText="Next page"
		  itemsPerPageText="Items per page:"
		  onChange={(e) => handlePaginationChange(e)}
		  page={pagination.page}
		  pageSize={pagination.limit}
          pageSizes={[5, 10, 15]}
		  size="md"
		  totalItems={pagination.total_item}
		/>
    </TableContainer>
	:
		<TableLoader {...helpers.tableLoaderProps(headers)}/>
	}
	{/* Create Update Staff popup */}
	<CreateUpdateClient open={createclient} setOpen={setCreateClient} id={selecteditem}/>
	{/* Create Update Staff popup */}
	<UploadClientCSV open={uploadclient} setOpen={setUploadClient}/>
	{/* Delete Confirmation popup */}
	<DeleteClientConfirmation open={deleteitem} setOpen={setDeleteItem} id={selecteditem} clientname={selectedname} />
    </>
  );
};

List.defaultProps = {
  collator: new Intl.Collator(),
  hasSelection: false,
  pageSize: 5,
  size: TABLE_SIZE.REGULAR,
  start: 0,
};

export default List;
