import { __trans } from '../../../_helpers'

export const Header = [
  {
    id: 'client_id',
    title: __trans('in_process_client_table_header.client_id'),
	active: true,
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'first_name',
    title: __trans('in_process_client_table_header.first_name'),
	active: true,
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'last_name',
    title: __trans('in_process_client_table_header.last_name'),
	active: true,
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'company',
    title: __trans('in_process_client_table_header.company'),
	active: true,
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'year',
    title: __trans('in_process_client_table_header.year'),
	active: true,
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'client_status',
    title: __trans('in_process_client_table_header.status'),
	active: true,
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'actions',
    title: __trans('in_process_client_table_header.actions'),
	active: true,
  },
  
];