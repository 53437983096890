import React, { useState } from 'react';
import { Button, ComboButton, MenuItem  } from '@carbon/react';
import { Add } from '@carbon/icons-react';
import { useNavigate } from "react-router-dom";
import { __trans, userDetails } from '../../../_helpers'
import helpers from '../../../_helpers/common';
import AdminRightNav from './AdminRightNav'
import FirmRightNav from './FirmRightNav'
import ClientRightNav from './ClientRightNav'
import ChangePassword from '../../common/profile/ChangePassword'
import ViewProfile from '../../common/profile/ViewProfile'
import ClientWelcomeMessage from '../../common/profile/ClientWelcomeMessage'

const SideBar = () => {
	const navigate = useNavigate();
	const [changepassword, setChangePassword] = useState(false);
	const [viewprofile, setViewProfile] = useState(false);
	const [clientwelcomemessage, setClientWelcomeMessage] = useState(false);
	function handleLogout() {
		localStorage.clear();
		navigate("/login");
	}
	
	const handleLogoClick = () => {
		if(userDetails('role') === 'Admin'){
			navigate("/dashboard")
		}else{
			navigate("/clients/in-progress-list")
		}
	}
	return (
	<>
		<div className="sidebar d-flex flex-column">
			<div className="sidebar-top text-center">
				<div className="logo" role="button" onClick={() => handleLogoClick()}>
					<img src="/images/logo.png" alt="logo"/>
				</div>
				<div className="">
				{userDetails('role') === 'Admin' ? 
					<ComboButton className="light-blue body-compact-02" label={helpers.truncateString(__trans('admin_profile_setting_button'), 12)} menuAlignment="bottom">
						<MenuItem label={__trans('admin_sidebar.view_profile')} onClick={() => setViewProfile(true)}/>
						<MenuItem label={__trans('admin_sidebar.change_password')} onClick={() => setChangePassword(true)} />
						{userDetails('role') === 'Firm' || userDetails('role') === 'Staff' ? 
							<MenuItem label={__trans('admin_sidebar.client_welcome_message')} onClick={() => setClientWelcomeMessage(true)} />
							: ''
						}
					</ComboButton>
					:
					<Button renderIcon={Add} className="light-blue body-compact-02" onClick={() => navigate('/clients/new-request/0')}>
						{__trans('new_request_button')}
					</Button>
				}
				</div>
			</div>
			{userDetails('role') === 'Admin' ? <AdminRightNav /> : (userDetails('role') === 'Client' ? <ClientRightNav /> : <FirmRightNav />)}
			<div className="mt-auto px-3">
				<div className="d-flex align-items-center mb-3">
					<img src={userDetails('user_image')} alt="User" className="me-2" />
					<p className="m-0 user-name">{userDetails('fullname')}</p>
				</div>
				<p className="logout-link pointer" onClick={handleLogout}>{__trans('logout_link')}</p>
			</div>
		</div>
		<ChangePassword open={changepassword} setOpen={setChangePassword}/>
		<ViewProfile open={viewprofile} setOpen={setViewProfile} id="0"/>
		<ClientWelcomeMessage open={clientwelcomemessage} setOpen={setClientWelcomeMessage}/>
	</>	
  )
}

export default SideBar