import React, { useState, useEffect } from 'react';
import { Modal, Tabs, TabList, Tab, TabPanels, TabPanel, Loading } from 'carbon-components-react';
import { Task, Identification, Query, DocumentAdd, Reply } from '@carbon/icons-react';
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import useToastAlert from '../../../hooks/useToastAlert'
import { __trans, makeRequest } from '../../../_helpers'

import RequestInfo from './view-request-partials/RequestInfo'
import SelectClient from './view-request-partials/SelectClient'
import QuestionAnswer from './view-request-partials/QuestionAnswer'
import UploadDocument from './view-request-partials/UploadDocument'
import ClientFeedback from './view-request-partials/ClientFeedback'


const ViewRequest = ({ open, setOpen, id }) => {
	const { addError } = useToastAlert();
	const { contentLoading } = useSelector((state) => state.common);
    const dispatch = useDispatch();
	const [selectedIndex, setSelectedIndex]     = useState(0);
	const [request, setRequest]                 = useState({});
	
	const getRequestData = async() => {
		await makeRequest("firm/get-client-request/" + id, {}, 'POST', addError)
			.then((res) => {
				if (res.type === "success") {
					setRequest(res.data);
				}
			});
	}
	
	useEffect(() => {
		if(id !== ''){
			getRequestData();
		}
	}, [id])
	
	const handleTabChange = (evt) => {
		setSelectedIndex(evt.selectedIndex);
	};
	
	return (
		<Modal size="lg" className="header-border" open={open} onRequestClose={() => setOpen(false)} 
			modalLabel=""
			modalHeading={__trans('view_request_title')}
			passiveModal={true}
			preventCloseOnClickOutside={true}
		>
			<Tabs onChange={handleTabChange} selectedIndex={selectedIndex}>
				<TabList aria-label="List of tabs">
					<Tab renderIcon={Task}>{__trans('tab_1_title')}</Tab>
					<Tab renderIcon={Identification}>{__trans('tab_2_title')}</Tab>
					<Tab renderIcon={Query}>{__trans('tab_3_title')}</Tab>
					<Tab renderIcon={DocumentAdd}>{__trans('tab_4_title')}</Tab>
					<Tab renderIcon={Reply}>{__trans('tab_5_title')}</Tab>
				</TabList>
				<TabPanels>
					<TabPanel><RequestInfo request={request} show={selectedIndex}/></TabPanel>
					<TabPanel><SelectClient request={request} show={selectedIndex}/></TabPanel>
					<TabPanel><QuestionAnswer request={request} show={selectedIndex}/></TabPanel>
					<TabPanel><UploadDocument request={request} show={selectedIndex}/></TabPanel>
					<TabPanel><ClientFeedback request={request} show={selectedIndex}/></TabPanel>
				</TabPanels>
			</Tabs>
		</Modal>
	);
};

export default ViewRequest;