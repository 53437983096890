import ToastNotification from "../common/ToastNotification"
import LanguageSwitcher from '../common/LanguageSwitcher';

const AuthLayout = ({children}) => {
  return (
    <div className="body-background">
			<div className="container py-4">
		<div className="align-items-center justify-content-between row">
			<div className="col-6 col-md-4">
				<div className="logo mt--20">
					<img src="/images/logo.png" alt="Logo" className="img-fluid" />
				</div>
			</div>
			
			<div className="col-6 col-md-4 d-flex justify-content-end">
				<LanguageSwitcher />
			</div>
		</div>
	</div>

		<div className="container">
			<div className="d-flex justify-content-end">
				<ToastNotification />
			</div>
			<div className="pb-5">
			{children}
			</div>
		</div>
	</div>
  )
}

export default AuthLayout
