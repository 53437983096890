import React, { useState, useEffect } from 'react';
import { Button, Loading } from 'carbon-components-react';
import { ArrowRight, Edit } from '@carbon/icons-react';
import { useSelector, useDispatch } from 'react-redux';
import useToastAlert from '../../../../hooks/useToastAlert'
import { __trans, getData } from '../../../../_helpers'
import SignContract from './SignContract';

const ContractSignUp = ({currentstep, handleNextPrevoius, requestdata}) => {
	//console.log('requestdata', requestdata)
	const { addError } = useToastAlert();
	const dispatch = useDispatch();
	const { contentLoading } = useSelector((state) => state.common);
	const [serverdata, setServerData] = useState([]);
	const [sign, setSign] = useState(false);
	const [signdocument, setSignDocument] = useState({});
	
	const getFormData = async () => {
		if(requestdata){
			await getData("firm/get-firm-client-contract", {client_id: requestdata.client_id, client_request_id: requestdata._id}, 'POST', addError, dispatch)
				.then((res) => {
					if (res.type === "success") {
						setServerData(res.data.docs);
					} 
				})
				.catch((error) => {
					
				});
		}
	}
	  
	useEffect(() => {
		if(currentstep === 0){
			getFormData();
		}
	}, [currentstep])
	
	const handleSignContract = (item) => {
		//console.log('selected item', item)
		
		/*if(requestdata.docuseal_form_info !== ''){
			var form_info = JSON.parse(requestdata.docuseal_form_info);
			//console.log('form_info', form_info)
			setSign(true);
			setSignDocument(item);
		}*/
		setSign(true);
		setSignDocument({});
	}
	
	//console.log('serverdata', serverdata)
    return (
        <>
			{/* Step 1 START  */}
			<div className="container mt-3">
				<div style={{ minHeight: '400px' }}>			
					<div className='row my-5'>
						<div className='col-10 mx-auto'>
						<div className="cds--data-table-header">
							<h4 className="cds--data-table-header__title" id="tc-:r1dn:-title">{__trans('account_setup_step1_info')}</h4>
						</div>
						<div className='bg-body-secondary border px-4 rounded'>
							<div className='d-flex flex-column gap-3 py-4'>	
								{requestdata.sign_document === 1 ? 
									<>
										{!contentLoading ? 
											<>
											{serverdata.length > 0 ? 
												<>
													{serverdata.map((item) => (
														<div key={item._id} className="align-items-center bg-body border d-flex justify-content-between px-2 py-2 shadow-sm">
															<p className="fs-14">{item.file_original_name}</p>
														</div>
													))}
													<div className="align-items-center d-flex justify-content-center">
														<Button className="btn--primary" size="sm" renderIcon={Edit} onClick={() => handleSignContract()}>Sign Documents</Button>
													</div>
												</>
											: 
												<div className="align-items-center bg-body border d-flex justify-content-between px-2 py-2 shadow-sm">
													<p className="fs-14">{__trans('no_document_to_sign')}</p>
												</div>
											}
											</>
										
										: <div className="d-flex text-center"><Loading className={'some-class'} withOverlay={false} /></div>
										}
									</>
								: 
									<div className='text-center my-5'>
										<h5>{__trans('no_document_sign_required')}</h5>
									</div>
								}
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='container'>
				<div className='col-10 my-3 mx-auto mb-5 text-end'>
					<Button className='btn--primary text-center' renderIcon={ArrowRight} size='md' onClick={() => handleNextPrevoius(currentstep + 1)}>
						<span className='pe-3'>Next</span> 
					</Button>
				</div>
			</div>
			<SignContract open={sign} setOpen={setSign} contract={signdocument} requestdata={requestdata}/>
		</>
    );
};

export default ContractSignUp;