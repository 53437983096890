import React from "react";
import { Dropdown } from '@carbon/react';

const items = [
    { id: 'en', text: 'ENG' },
    { id: 'sp', text: 'SPN' },
  ];

const LanguageSwitcher = () => {
	const handleLanguageChange = (e) => {
		const newLang = e.selectedItem.id;
		localStorage.setItem('user_language', newLang);
		window.location.reload();
	};
	
	const getActiveLang = () => {
		const lang      = localStorage.getItem('user_language');
		var active_lang = 'sp'
		if(lang){
			 active_lang = lang;
		}
		return active_lang;
	}
	
	const checkIntialItemSelected = () => {
		let current_lang = getActiveLang();
		const current_item = items.filter((item) => item.id === current_lang);
		if(current_item.length > 0){
			return current_item[0];
		}else{
			return items[1];
		}
		
	}

  return (
	<div className="col ">
		<div className="ms-auto d-flex align-items-center lang-switcher" style={{width: 100}}>
			<img src={`/images/flags/${getActiveLang()}.png`} alt="" title="" /> 
			<Dropdown id="inline" titleText="" initialSelectedItem={checkIntialItemSelected()} size="sm" label={checkIntialItemSelected().text} type="inline" onChange={handleLanguageChange} items={items} itemToString={item => item ? item.text : ''} />
		</div>
	</div>
  );
};

export default LanguageSwitcher;
