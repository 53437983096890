import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Tabs, TabList, Tab, TabPanels, TabPanel, ComboButton, MenuItem } from '@carbon/react';
import useToastAlert from '../../../hooks/useToastAlert'
import DeleteConfirmation from '../../common/DeleteConfirmation';
import { useSelector, useDispatch } from 'react-redux';
import { __trans, getData } from '../../../_helpers'
import TabContent from './tab-partial/TabContent'
import CreateUpdatePackageRequestTemplate from './create-update-package/PackageRequestTemplate'
import CreateUpdateDocumentTemplate from './create-update-template/CreateUpdateDocumentTemplate'

const List = () => {
	const { addError } = useToastAlert();
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const { contentLoading, reloaddata } = useSelector((state) => state.common);
	const dispatch = useDispatch();
	const [cudt, setCUDT]      = useState(false);
	const [packagetemplate, setPackageTemplate] = useState(false);
	const [deleteitem, setDeleteItem] = useState(false);
	const [deletemodule, setDeleteModule] = useState('');
	const [itemid, setItemId] = useState('0');
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [rows, setRows] = useState([]);
	const [paginationdata, setPagination] = useState({currentpage : 0, limit: 12, total_pages: 0});
	
	const getPackageList = async () => {
	    await getData("request-package/list", {page: paginationdata.currentpage + 1, limit: paginationdata.limit}, 'POST', addError, dispatch)
			.then((res) => {
				if (res.type === "success") {
					setRows(res.data.docs)
					setPagination({...paginationdata, total_pages: res.data.totalPages})
				}
			});
	}
  
	const getTemplateList = async (type) => {
	   await getData("template/list", {type: type, page: paginationdata.currentpage + 1, limit: paginationdata.limit}, 'POST', addError, dispatch)
			.then((res) => {
				if (res.type === "success") {
					setRows(res.data.docs)
					setPagination({...paginationdata, total_pages: res.data.totalPages})
				}
			});
	}
  
	useEffect(() => {
		var tab     = searchParams.get("tab") !== null ? Number(searchParams.get("tab")) : 0;
		let type    = searchParams.get("type");
		let item_id = searchParams.get("id");
		//tab = tab !== null ? Number(tab) : 0;;
		if(tab !== null){
			setSelectedIndex(tab);
			if(tab === 0){
				getPackageList();
			}else if(tab === 1){
				getTemplateList(1)
			}else{
				getTemplateList(2)
			}
		}else{
			getPackageList();
		}
		
		if(item_id !== null && (type !== null && type === 'edit')){
			if(tab === 0){
				handleEditItem(item_id, 'package')
			}else if(tab === 2){
				handleEditItem(item_id, 'cudt')
			}
		}
	}, []);
  
	useEffect(() => {
		if(reloaddata){
			clearQueryParams();
		}
		if(selectedIndex === 0){
			getPackageList();
		}else if(selectedIndex === 1){
			getTemplateList(1)
		}else{
			getTemplateList(2)
		}
	}, [reloaddata, selectedIndex, paginationdata.currentpage])
	
	const clearQueryParams = () => {
		if (searchParams.has('tab')) {
		  searchParams.delete('tab');
		  setSearchParams(searchParams);
		}
		if (searchParams.has('type')) {
		  searchParams.delete('type');
		  setSearchParams(searchParams);
		}
		if (searchParams.has('id')) {
		  searchParams.delete('id');
		  setSearchParams(searchParams);
		}
	}
  
	const handleDeleteItem = (id, module) => {
		if(module === 'package'){
			setDeleteModule(module);
		}else{
			setDeleteModule('template');
		}
		setItemId(id);
		setDeleteItem(true);
	}
	
	const handleEditItem = (id, module) => {
		setItemId(id);
		if(module === 'package'){
			setPackageTemplate(true);
		}else if(module === 'cudt'){
			setCUDT(true)
		}
	}
  
	const handleTabChange = (evt) => {
		setSelectedIndex(evt.selectedIndex);
		setRows([]);
		setPagination({...paginationdata, currentpage : 0, limit: 12, total_pages: 0})
	};
	
	const changePage = (e) => {
		setPagination({...paginationdata, currentpage: e})
	}
	
  /* eslint-disable no-script-url */
  return (
	<>
		<div className="row align-items-end">
			<div className="col-9">
				<div className="cds--data-table-header">
					<h4 className="cds--data-table-header__title">{__trans('request_package_text')}</h4>
					<p className="cds--data-table-header__description">{__trans('request_package_info')}</p>
				</div>
			</div>
			<div className="col-3">
				<ComboButton label="Create a Template" className="btn--primary">
					<MenuItem label="Package Request" onClick={() => handleEditItem('0', 'package')} />
					<MenuItem label="Custom Questions" onClick={() => navigate("/template/create-update/0?back_to=templates?tab=1")}/>
					<MenuItem label="Document Request List" onClick={() => handleEditItem('0', 'cudt')} />
				</ComboButton>
			</div>
		</div>
		<Tabs onChange={handleTabChange} selectedIndex={selectedIndex}>
			<TabList aria-label="List of tabs">
				<Tab>{__trans('package_request_tab')}</Tab>
				<Tab>{__trans('custom_question_tab')}</Tab>
				<Tab>{__trans('document_request_list_tab')}</Tab>
			</TabList>
			<TabPanels>
				<TabPanel className="px-0 mt-3">
					<TabContent key="tab-content-1" rows={rows} contentLoading={contentLoading} module='package' onEdit={handleEditItem} onDelete={handleDeleteItem}  onPageChange={changePage} paginationdata={paginationdata}/>
				</TabPanel>
				<TabPanel className="px-0 mt-3">
					<TabContent key="tab-content-2" rows={rows} contentLoading={contentLoading} module='question' onEdit={handleEditItem} onDelete={handleDeleteItem} onPageChange={changePage} paginationdata={paginationdata}/>
				</TabPanel>
				<TabPanel className="px-0 mt-3">
					<TabContent key="tab-content-3" rows={rows} contentLoading={contentLoading} module='cudt' onEdit={handleEditItem} onDelete={handleDeleteItem} onPageChange={changePage} paginationdata={paginationdata}/>
				</TabPanel>
			</TabPanels>
		</Tabs>
		{/* Package Request Template popup */}	
		<CreateUpdatePackageRequestTemplate open={packagetemplate} setOpen={setPackageTemplate} id={itemid}/> 
		{/* Document Template popup */}
		<CreateUpdateDocumentTemplate open={cudt} setOpen={setCUDT} id={itemid}/>
		{/* Delete Confirmation popup */}
		<DeleteConfirmation open={deleteitem} setOpen={setDeleteItem} module={deletemodule} id={itemid}/>
	</>
	);
};

export default List;