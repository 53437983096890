import { __trans } from '../../../_helpers'

export const Header = [
  {
    id: 'order_id',
    title: __trans('purchase_request_table_header.order_id'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'quantity',
    title: __trans('purchase_request_table_header.quantity'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'unit_amount',
    title: __trans('purchase_request_table_header.unit_amount'),
    //sortCycle: 'bi-states-from-ascending',
  },
   {
    id: 'total_amount',
    title: __trans('purchase_request_table_header.total_amount'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'created_at',
    title: __trans('purchase_request_table_header.created_at'),
    //sortCycle: 'bi-states-from-ascending',
  },
   {
    id: 'payment_status',
    title: __trans('purchase_request_table_header.payment_status'),
    //sortCycle: 'bi-states-from-ascending',
  }
];