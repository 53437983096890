import { __trans } from '../../../_helpers'

export const Header = [
  {
    id: '_id',
    title: __trans('request_price_tabel_header.s_no'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'name',
    title: __trans('request_price_tabel_header.plan_name'),
    //sortCycle: 'bi-states-from-ascending',
  },
   {
    id: 'description',
    title: __trans('request_price_tabel_header.plan_description'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'country',
    title: __trans('request_price_tabel_header.plan_country'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'fullprice',
    title: __trans('request_price_tabel_header.plan_price'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'status',
    title: __trans('request_price_tabel_header.status'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'actions',
    title: __trans('request_price_tabel_header.actions')
  },
  
];