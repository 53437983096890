import React from 'react';
import { Modal, Tag } from '@carbon/react';
import helpers from '../../../../_helpers/common';
import { __trans, userDetails } from '../../../../_helpers';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PayPalButton from './paypal-button/PayPalButton';

const CLIENTID = helpers.paypalDetail('CLIENTID');
//console.log('CLIENTID', CLIENTID);

const initialOptions = {
    clientId: CLIENTID,
    currency: "USD",
    intent: "capture",
};

const RequestBuyPayment = ({open, setOpen, buyform}) => {
	//console.log('buyform', buyform)
	//console.log('buyform.request_purchase', buyform.request_purchase)
	return (
		<Modal className="header-border" size="sm" open={open} onRequestClose={() => setOpen(false)} 
			modalHeading={__trans('setting_buy_request')}
			modalLabel=""
			passiveModal={true}
			preventCloseOnClickOutside={true}
		>
		<div className="row">
			<div className="d-flex justify-content-between mb-5">
				<div className="">
					<Tag className="" type='red'>{__trans('forms.request_purchase')}: {buyform.request_purchase}</Tag>
				</div>
				<div className="">
					<Tag className="" type='blue'>{__trans('request_buy_amount')}: ${buyform.price}</Tag>
				</div>
			</div>
			<PayPalScriptProvider options={initialOptions}>
				<PayPalButton firmID={userDetails('firm_id')} itemprice={buyform.price} unit_price={buyform.unit_price} quantity={buyform.request_purchase} setOpen={setOpen}/>
			</PayPalScriptProvider>
		</div>
	</Modal>
	)
}

export default RequestBuyPayment