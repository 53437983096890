import React, { useState  } from 'react';
import { Link } from "react-router-dom";
import { Modal, Form, Stack, InlineNotification, OrderedList, ListItem } from '@carbon/react';
import { useCSVReader, lightenDarkenColor, formatFileSize } from 'react-papaparse';
import { useDispatch } from 'react-redux';
import { callCommonAction } from '../../../../redux/Common/CommonReducer';
import useToastAlert from '../../../../hooks/useToastAlert';
import { __trans, makeRequest } from '../../../../_helpers';

const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT   = lightenDarkenColor( DEFAULT_REMOVE_HOVER_COLOR, 40);

const styles = {
  zone: {
    alignItems: 'center',
    border: '2px dashed #CCC',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: 20,
  },
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: 120,
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  },
  name: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: '#686868',
  },
  default: {
    borderColor: '#CCC',
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  },
};

const zoneHover = false;
const UploadClientCSV = ({ open, setOpen }) => {
	//console.log('item id', id)
	const { addError } = useToastAlert();
	const { CSVReader } = useCSVReader();
	const dispatch = useDispatch();
	//console.log('CSVReader', CSVReader)
	const [fileselected, setFileSelected] = useState(false);
	const [uploaddatawarning, setUploadDataWarning] = useState('');
	const [uploaddataerror, setUploadDataError] = useState('');
	const [uploaddata, setUploadData] = useState([]);
	const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR);
	const [submitcontrol, setSubmitControle] = useState({ status: 'inactive', description: __trans('submit_button') });
	
	const resetSubmitControlStatus = () => {
		setSubmitControle({...submitcontrol, status: 'inactive', description: __trans('submit_button')})
	};
	
	const handleSelectedFileData = (results) => {
		//console.log('92---------------------------');
		//console.log(results);
		//console.log('94---------------------------');
		setUploadDataWarning('');
		setUploadData(results.data);
		setUploadDataError('');
		setFileSelected(true);
	}
	
	const validateForm = () => {
		let validateData = true;
		if (uploaddata.length === 0) {
			setUploadDataError(__trans('error_msg.select_valid_csv_error'));
			validateData = false;
		} else {
			setUploadDataError('');
		}
		return validateData;
	}
	
	const handleSubmit = (e) => {
		if (validateForm()) {
			submitForm({ client_data: JSON.stringify(uploaddata) })
		}
	}
	
	const submitForm = async (formData) => {
		setSubmitControle({...submitcontrol, status: 'active'})
		await makeRequest('users/upload-client-csv', formData, 'POST', addError)
			.then((res) => {
				dispatch(callCommonAction({ reloaddata: true }));
				setSubmitControle({...submitcontrol, status: 'finished'})
				if (res.type === "success") {
					if(res.client_limit_reached === 'yes'){
						let ln_msg = __trans('server_msg.client_add_limit_reached');
						setUploadDataWarning(ln_msg)
					}else if(res.existing_user !== ''){
						var ln_msg = __trans('server_msg.client_already_exist');
						ln_msg = ln_msg + ' { ' + res.existing_user + ' }';
						setUploadDataWarning(ln_msg)
					}else{
						setUploadData([]);
						setFileSelected(false);
						setUploadDataWarning('');
						//setTimeout(() => {
							setOpen(false)
						//}, 2000);
					}
				}
			}).catch((error) => {
				setSubmitControle({...submitcontrol, status: 'finished'})
			});
	}
	
	const handleCancel = () => {
		setUploadData([]);
		setUploadDataWarning('');
		setOpen(false);
		setFileSelected(false);
	}
	
	return (
		<Modal className="" open={open} onRequestClose={() => handleCancel()} 
			modalLabel={__trans('upload_client_page_label')}
			modalHeading={__trans('upload_client_page_heading')}
			preventCloseOnClickOutside={true}
			primaryButtonText={__trans('submit_button')} 
			secondaryButtonText={__trans('cancel_button')} 
			onRequestSubmit={() => handleSubmit() }
			loadingStatus={submitcontrol.status} 
			loadingDescription={submitcontrol.description} 
			onLoadingSuccess={resetSubmitControlStatus}
		>
		<Form aria-label="create/update template form">
			<Stack gap={5}>
				{uploaddatawarning !== '' ?
					<InlineNotification
						aria-label="closes notification"
						lowContrast={true}
						kind="warning"
						title={__trans('server_msg.warning_title')}
						subtitle={uploaddatawarning}
					/>
					: ""
				}
				<InlineNotification
					lowContrast={true}
					kind="info"
					title={__trans('server_msg.warning_title')}
					subtitle={<OrderedList><ListItem>{__trans('upload_client_limit_reached_info')}</ListItem><ListItem>{__trans('upload_client_first_row_info')}</ListItem></OrderedList>}
				/>
				<div>
					<Link to="/files/Client-Upload-Sample.csv" target="_blank" download>{ __trans('download_sample_client_csv') }</Link>
				</div>
				<CSVReader onUploadAccepted={(results: any) => {handleSelectedFileData(results)}} noDrag>
				  {({
					getRootProps,
					acceptedFile,
					ProgressBar,
					getRemoveFileProps,
					Remove,
				  }: any) => (
					<>
					  <div
						{...getRootProps()}
						style={Object.assign(
						  {},
						  styles.zone,
						  zoneHover && styles.zoneHover
						)}
					  >
						{fileselected && acceptedFile ? (
						  <>
							<div style={styles.file}>
							  <div style={styles.info}>
								<span style={styles.size}>
								  {formatFileSize(acceptedFile.size)}
								</span>
								<span style={styles.name}>{acceptedFile.name}</span>
							  </div>
							  <div style={styles.progressBar}>
								<ProgressBar />
							  </div>
							  <div role="button"
								{...getRemoveFileProps()}
								style={styles.remove}
								onMouseOver={(event: Event) => {
								  event.preventDefault();
								  setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
								}}
								onMouseOut={(event: Event) => {
								  event.preventDefault();
								  setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
								}}
							  >
								<Remove color={removeHoverColor} />
							  </div>
							</div>
						  </>
						) : (
							<div className="pe-auto" role="button">
								{__trans('forms.select_client_csv')}
							</div>
						)}
					  </div>
					</>
				  )}
				</CSVReader>
				{uploaddataerror !== '' ? <span className="text-danger">{ uploaddataerror }</span> : ''}
			</Stack>
		</Form>
	</Modal>
	)
}

export default UploadClientCSV
