import React, { useState, useEffect } from 'react';
import { Stack, Modal, TextArea, Loading } from '@carbon/react';
import { useSelector, useDispatch } from 'react-redux';
import useToastAlert from '../../../hooks/useToastAlert';
import { __trans, makeRequest, getData, userDetails } from '../../../_helpers';

const ClientWelcomeMessage = ({ open, setOpen }) => {
	
	const { addError } = useToastAlert();
	const { contentLoading } = useSelector((state) => state.common);
	 const dispatch = useDispatch();
	const [submitcontrol, setSubmitControle] = useState({ status: 'inactive', description: __trans('submit_button') });
	const [form, setForm] = useState({ welcome_msg: '', welcome_msg_error: '' });
	
	const resetSubmitControlStatus = () => {
		setSubmitControle({...submitcontrol, status: 'inactive', description: __trans('submit_button')})
	};
	
	const getFirmData = async () => {
		var firm_id = userDetails('firm_id');
		await getData("users/get-firm-data/" + firm_id, {}, 'POST', addError, dispatch)
			  .then((res) => {
				if (res.type === "success") {
					setForm({...form, welcome_msg: res.data.client_welcome_msg})
				} 
			  }).catch((error) => {
					
			  });
	  }
	  
	useEffect(() => {
		if(open){
			getFirmData();
		}
	}, [open])
	
	const valueChanged = (e) => {
		const { name, value } = e.target;
		let newEdit = { ...form };
		newEdit[name] = value;
		newEdit[name + '_error'] = '';
		setForm(newEdit);
	}

	const validateForm = () => {
		let newEdit = { ...form };
		let validateData = true;
		
		if (form.welcome_msg === '') {
			newEdit.welcome_msg_error = __trans('error_msg.client_welcome_empty');
			validateData = false;
		} else {
			newEdit.current_password_error = '';
		}
		
		setForm(newEdit);
		return validateData;
	}
	
	const handleSubmit = (e) => {
		//Before submit validate form
		if (validateForm()) {
			submitForm({ client_welcome_msg: form.welcome_msg })
		}
	}

	const submitForm = async (formData) => {
		var firm_id = userDetails('firm_id');
		await makeRequest("users/update-client-welcome-message/" + firm_id, formData, 'POST', addError)
			.then((res) => {
				setSubmitControle({...submitcontrol, status: 'finished'})
				if (res.type === "success") {
					setOpen(false)
				}
			}).catch((error) => {
				setSubmitControle({...submitcontrol, status: 'finished'})
			});
	}
	
	const handleCancel = () => {
		setForm({ welcome_msg: '', welcome_msg_error: '' });
		setOpen(false)
	}
	
    return (
        <Modal open={open} onRequestClose={() => handleCancel()} 
			modalHeading={__trans('update_client_welcome_message_heading')}
			loadingStatus='active'
			preventCloseOnClickOutside={true}
			primaryButtonText={__trans('submit_button')} 
			secondaryButtonText={__trans('cancel_button')} 
			onRequestSubmit={() => handleSubmit() }
			loadingStatus={submitcontrol.status} 
			loadingDescription={submitcontrol.description} 
			onLoadingSuccess={resetSubmitControlStatus}
		>
		<Stack gap={5}>
		{!contentLoading ?
			<TextArea name="welcome_msg" 
				value={form.welcome_msg}
				id="current_password"
				onChange={valueChanged}
				rows={10}
				labelText={__trans('forms.client_welcome_message_label')}
				placeholder={__trans('forms.client_welcome_message_placeholder')}
				invalid={form.welcome_msg_error !== '' ? true : false}
				invalidText={form.welcome_msg_error}
			/>
			:
			<div className='d-flex justify-content-center'>
				<Loading className='text-center' withOverlay={false} />
			</div>
		}
		</Stack>
      </Modal>
    );
};

export default ClientWelcomeMessage;
