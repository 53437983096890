import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useSearchParams } from "react-router-dom";
import { CheckboxGroup, Stack, Breadcrumb, BreadcrumbItem, Column, SkeletonText, Container } from '@carbon/react';
import { Form, Checkbox, TextInput, TextArea, Select, SelectItem, Button, InlineLoading, Loading } from 'carbon-components-react';
import { TrashCan as Delete, Edit } from '@carbon/icons-react';
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../../../redux/Common/CommonReducer';
import useToastAlert from '../../../../hooks/useToastAlert'
import helpers from '../../../../_helpers/common'
import { __trans, makeRequest, getData } from '../../../../_helpers'
import CreateUpdateQuestion from '../create-update-question/CreateUpdateQuestion';
import DeleteConfirmation from '../../../common/DeleteConfirmation';
import QuestionList from './create-update-template-partials/QuestionList';
import SelectedQuestionList from './create-update-template-partials/SelectedQuestionList';

const CreateUpdateTemplate = () => {
	const { addError } = useToastAlert();
	const { id }  = useParams();
	const [searchParams] = useSearchParams();
	//console.log('searchParams', searchParams);
	//console.log('searchParams back_to', searchParams.get('back_to'));
	const navigate = useNavigate();
	const [createupdatequestion, setCreateUpdateQuestion] = useState(false);
	const [deletequestion, setDeleteQuestion]             = useState(false);
	const [itemid, setItemId]                             = useState('0');
	const { loading, contentLoading, reloaddata } = useSelector((state) => state.common);
    const dispatch = useDispatch();
	const [selectoptionerror, setSelectOptionError] = useState('');
	const [optionrows, setOptionRows] = useState([]);
	const [filteredoptionrows, setFilteredOptionRows] = useState([]);
	const [seletedfilteredrows, setSeletedFilteredRows] = useState([]);
	const [selectedoption, setSelectedOption] = useState([]);
	const [form, setForm] = useState({ type: 1,  type_error: '', name: '',  name_error: '', description: ''});
	
	const getFormData = async () => {
		 if(id !== '0'){
			await getData("template/form/"+id, {}, 'POST', addError, dispatch)
				.then((res) => {
					if (res.type === "success") {
						setForm({ ...form, type: res.data.type, name: res.data.name, description: res.data.description });
						if(res.data.type === 1 && res.data.template_questions.question.length > 0){
							setSelectedOption(res.data.template_questions.question);
						}
						if(res.data.type === 2 && res.data.template_documents.documents.length > 0){
							setSelectedOption(res.data.template_documents.documents);
						}
						if(res.data.type === 1){
							getOptionList("question/list");
						}else{
							getOptionList("document/list");
						}
					} 
				})
				.catch((error) => {
					
				});
		 }
	  }
	  
	useEffect(() => {
		getFormData();
		getOptionList("question/list");
	}, [])
	
	useEffect(() => {
		if(Number(form.type) === 1){
			getOptionList("question/list");
		}else if(Number(form.type) === 2){
			getOptionList("document/list");
		}
	}, [reloaddata])
	
	const getOptionList = async (end_point) => {
		setOptionRows([]);
		await makeRequest(end_point, {}, 'POST', addError)
		  .then((res) => {
				dispatch(callCommonAction({ reloaddata: false }));
				if (res.type === "success") {
					setOptionRows(res.data.docs)
					setFilteredOptionRows(res.data.docs);
					setSeletedFilteredRows(res.data.docs);
				} 
		  }).catch((error) => {
				dispatch(callCommonAction({ reloaddata: false }));
		  });
	}

	const handleCreateUpdateQuestion = (id) => {
		setCreateUpdateQuestion(true);
		setItemId(id);
	}
	
	const handleDeleteQuestion = (id) => {
	  setItemId(id);
	  setDeleteQuestion(true);
	}

	const setOptionIsChecked = (e) => {
		let target_item = e.target.value;
		const newArray = selectedoption.filter((item, index) => item !== target_item);
		setSelectedOption(newArray); // Updates the state with the new array
		if(e.target.checked){
			setSelectedOption(prevState => [...prevState, target_item])
		}
	}
	
	const searchQuestion = (e) => {
		var searchString = e.target.value;
		var filtered = optionrows.filter((row) => {
								//console.log('row', row)
								if(String(row.name ?? '').toLowerCase().indexOf(searchString.toLowerCase()) >= 0){
									if(!selectedoption.includes(row._id)){
										return row;
									}
								}
							}
						)
		setFilteredOptionRows(filtered);				
	}
	
	const searchSelectedQuestion = (e) => {
		var searchString = e.target.value;
		if(searchString !== ''){
			var filtered = optionrows.filter((row) => {
									//console.log('row', row)
									if(String(row.name ?? '').toLowerCase().indexOf(searchString.toLowerCase()) >= 0){
										if(selectedoption.includes(row._id)){
											return row;
										}
									}
								}
							)
			setSeletedFilteredRows(filtered);
		}else{
			setSeletedFilteredRows(optionrows);
		}	
	}
	
	const valueChanged = (e) => {
		const { name, value } = e.target;
		let newEdit = { ...form };
		newEdit[name] = value;
		newEdit[name + '_error'] = '';
		setForm(newEdit);
		if(name === 'type' && value === '1'){
			getOptionList("question/list");
			setSelectedOption([]);
		}else if(name === 'type' && value === '2'){
			getOptionList("document/list");
			setSelectedOption([]);
		}
	}
	
	const validateForm = () => {
		let newEdit = { ...form };
		let validateData = true;
		
		if (helpers.isEmpty(form.name)) {		
			newEdit.name_error = __trans('error_msg.template_name_error');
			validateData = false;
		} else {
			newEdit.name_error = '';
		}
		if (form.type === '') {
			newEdit.type_error = __trans('error_msg.template_type_error');
			validateData = false;
		} else {
			newEdit.type_error = '';
		}
		
		if(form.type !== '' && (optionrows.length > 0 && selectedoption.length === 0)){
			setSelectOptionError(__trans('error_msg.template_select_question'));
			validateData = false;
		}else{
			setSelectOptionError('');
		}
		setForm(newEdit);
		return validateData;
	}
	
	const handleSubmit = (e) => {
		if (validateForm()) {
			submitForm({ type: form.type, name: form.name, description: form.description, options: selectedoption })
		}
	}

	const submitForm = async (formData) => {
		dispatch(callCommonAction({ loading: true }));
		var submit_url = id === '0' ? 'template/create' : 'template/update/' + id;
		await makeRequest(submit_url, formData, 'POST', addError)
			.then((res) => {
				dispatch(callCommonAction({ loading: false }));
				if (res.type === "success") {
					let ln_msg = __trans('server_msg.'+res.message);
					addError(ln_msg, res.type)
					var back_to = searchParams.get('back_to');
					if(back_to === null){
						navigate('/templates?tab=1');
					}else{
						navigate('/'+back_to);
					}
				}
			}).catch((error) => {
				dispatch(callCommonAction({ loading: false }));
			});
	}
	//console.log('form', form)
    return (
        <>
		<div className="align-items-center d-flex w-100 mt-3">
		<div className="landing-page__banner mb-3 col-md-9">
			<Breadcrumb noTrailingSlash>
				<BreadcrumbItem>
					<Link to="/templates">Templates</Link>
				</BreadcrumbItem>
				<BreadcrumbItem>
					Custom Question Template
				</BreadcrumbItem>
			</Breadcrumb>
			<p className="mt-1">{id === '0' ? __trans('add_template_text') : __trans('edit_template_text')}</p>
		</div>
		<div className="landing-page__banner mb-3 col-md-3 text-end">
		{Number(form.type) === 1 ? 
			<Button className="btn--primary" onClick={() => handleCreateUpdateQuestion('0')} size="sm" kind="primary">
				{__trans('create_new_question_button')}
			</Button>
		:''
		}
		</div>
		</div>
		{!contentLoading ?
			<Form aria-label="create/update template form" className="border rounded p-3">
				<Stack gap={5}>
					<div className="row">
						<div className="col-6">
							<TextInput type="text" name="name" id="template_name" 
								value={form.name}
								onChange={valueChanged} 
								labelText={__trans('forms.template_name')} 
								placeholder={__trans('forms.template_name_placeholder')} 
								invalid={form.name_error !== '' ? true : false}
								invalidText={form.name_error}
							/>
						</div>
						<div className="col-6">
							<TextArea id="description" name="description"
								maxCount={100}
								value={form.description}
								onChange={valueChanged} 
								labelText={__trans('forms.template_description')}
								placeholder={__trans('forms.template_description_placeholder')}
								rows={2}
							/>
						</div>
					</div>
					<hr className="my-1" />
					{/*<Select name="type" id="type" value={form.type}
						labelText={__trans('forms.template_type')} 
						helperText={<span className="text-warning">{__trans('change_template_type')}</span>}
						invalid={form.type_error !== '' ? true : false}
						invalidText={form.type_error}
						onChange={valueChanged}
					>
						<SelectItem  disabled value="" text={__trans('forms.template_type_placeholder')} />
						<SelectItem value="1" text="Question" />
						<SelectItem value="2" text="Document List" />
					</Select>*/}
					<div className="">
						<div className="row">
							<div className="col-6">
								<QuestionList optionrows={filteredoptionrows} selectedoption={selectedoption} 
									setOptionIsChecked={setOptionIsChecked} 
									handleCreateUpdateQuestion={handleCreateUpdateQuestion} 
									handleDeleteQuestion={handleDeleteQuestion} 
									selectoptionerror={selectoptionerror}
									searchQuestion={searchQuestion}
								/>
							</div>
							<div className="col-6">
								<SelectedQuestionList optionrows={seletedfilteredrows} selectedoption={selectedoption} 
									setOptionIsChecked={setOptionIsChecked}
									searchSelectedQuestion={searchSelectedQuestion}
								/>	
							</div>
							
							</div>	
						<div>
						</div>
					</div>
					<div className="text-end">
						{!loading ? 
							<Button className='dark-blue' kind="primary" onClick={handleSubmit}>
								{__trans('submit_button')}
							</Button>
						:
							<Button kind="secondary" size="md" as="div" role="button">
								<InlineLoading className="text-info" style={{ marginLeft: '1rem' }} description="Wait..." status="active" aria-live="off"/>
							</Button>
						}
					</div>
				</Stack>
			</Form>
			
		:
			<div className='d-flex justify-content-center'>
				<Loading className='text-center' withOverlay={false} />
			</div>
		}
		{/* Create Update question popup */}
		<CreateUpdateQuestion open={createupdatequestion} setOpen={setCreateUpdateQuestion} id={itemid}/>
		{/* Delete Confirmation popup */}
		<DeleteConfirmation open={deletequestion} setOpen={setDeleteQuestion} module='question' id={itemid}/>
		</>
    );
};
export default CreateUpdateTemplate