import { __trans } from '../../../_helpers'

export const Header = [
  {
    id: 'client_id',
    title: __trans('deleted_client_table_header.client_id'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'first_name',
    title: __trans('deleted_client_table_header.first_name'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'last_name',
    title: __trans('deleted_client_table_header.last_name'),
    //sortCycle: 'bi-states-from-ascending',
  },
   {
    id: 'company',
    title: __trans('deleted_client_table_header.company'),
    //sortCycle: 'bi-states-from-ascending',
  },
   {
    id: 'deleted_at',
    title: __trans('deleted_client_table_header.deletion_date'),
    //sortCycle: 'bi-states-from-ascending',
  },
   {
    id: 'send_at',
    title: __trans('deleted_client_table_header.delivered_date'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'client_status',
    title: __trans('deleted_client_table_header.status'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'actions',
    title: __trans('delivered_client_table_header.actions')
  },
  
];