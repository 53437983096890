import { __trans } from '../../../_helpers'

export const Header = [
  {
    id: 'client_id',
    title: __trans('client_table_header.client_id'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'first_name',
    title: __trans('client_table_header.first_name'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'last_name',
    title: __trans('client_table_header.last_name'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'company',
    title: __trans('client_table_header.company'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'cuil_cuit',
    title: __trans('client_table_header.cuil_cuit'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'email',
    title: __trans('client_table_header.email'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'phone_no',
    title: __trans('client_table_header.phone_no'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'actions',
    title: __trans('client_table_header.actions')
  },
  
];