import React, { useState } from 'react';
import { Modal, Stack, Toggle } from 'carbon-components-react';
import { __trans } from '../../_helpers'

const TabelColomnSwitch = ({ open, setOpen, activeheader, setActiveHeader }) => {
	
	const [headers, setHeadersStatus] = useState(activeheader);
	
	const buttonToggled = (e, item_id) => {
		var new_headers = headers.map((head) => {
			if(head.id === item_id){
				return {...head, active: e}
			}else{
				return head;
			}
		});
		setHeadersStatus(new_headers);
	}
	
	const handleSubmit = () => {
		setActiveHeader(headers);
		handleCancel();
	}
	
	const handleCancel = () => {
		setOpen(false);
	}
	
	return (
	   <Modal className="header-border two-buttons" open={open} onRequestClose={() => handleCancel()} 
			modalHeading={__trans('common.colomn_configration')} 
			modalLabel=""
			preventCloseOnClickOutside={true}
			size='sm'
			primaryButtonText={__trans('save_button')} 
			secondaryButtonText={__trans('cancel_button')} 
			onSecondarySubmit={() => handleCancel()}
			onRequestSubmit={() => handleSubmit() }
		>
		   <Stack gap={3}>
			   {headers.length > 0 ?
					headers.map((item, index) => 
						<Toggle key={item.id} labelText={item.title} 
							hideLabel={true} 
							onToggle={(e) => buttonToggled(e, item.id)} 
							labelA="Off" labelB="On" 
							toggled={item.active} 
							id={item.id} 
						/>
					)
				:''
			   }				
		   </Stack>
	   </Modal>
	);
};

export default TabelColomnSwitch;