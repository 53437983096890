import React, { useState } from 'react';
import { Modal } from 'carbon-components-react';
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import useToastAlert from '../../../hooks/useToastAlert'
import { __trans, makeRequest } from '../../../_helpers'

const SendRequestConfirmation = ({ open, setOpen, id }) => {
	const { addError } = useToastAlert();
	const dispatch = useDispatch();
	const [submitcontrol, setSubmitControle] = useState({ status: 'inactive', description: __trans('send_button') });
	
	const resetStatus = () => {
		setSubmitControle({...submitcontrol, status: 'inactive', description: __trans('send_button')})
	};
	
	const handleSendSelected = async (id) => {
		setSubmitControle({...submitcontrol, status: 'active'})
		await makeRequest('firm/send-request-to-client/' + id, {}, 'POST', addError)
			.then((res) => {
				setSubmitControle({...submitcontrol, status: 'finished'})
				dispatch(callCommonAction({ reloaddata: true }));
				if (res.type === "success") {
					//setTimeout(() => {
						setOpen(false)
					//}, 2000);
				} 
			}).catch((error) => {
				setSubmitControle({...submitcontrol, status: 'finished'})
			});
	}
	
	return (
	   <Modal open={open} onRequestClose={() => setOpen(false)} 
			preventCloseOnClickOutside={true}
			modalHeading={__trans('common.send_request_page_info')} 
			modalLabel={__trans('common.send_request_page_heading')} 
			primaryButtonText={__trans('send_button')} 
			secondaryButtonText={__trans('cancel_button')} 
			onRequestSubmit={() => handleSendSelected(id)}
			loadingStatus={submitcontrol.status} 
			loadingDescription={submitcontrol.description} 
			onLoadingSuccess={resetStatus}
	   />
	);
};

export default SendRequestConfirmation;