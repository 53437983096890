import React, { useState, useEffect } from 'react';
import {Modal, CheckboxGroup, Stack } from '@carbon/react';
import { Form, Checkbox, TextInput, TextArea, Select, SelectItem, Loading } from 'carbon-components-react';
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../../../redux/Common/CommonReducer';
import useToastAlert from '../../../../hooks/useToastAlert'
import helpers from '../../../../_helpers/common'
import { __trans, makeRequest } from '../../../../_helpers'

const PackageRequestTemplate = ({ open, setOpen, id }) => {
	const { addError } = useToastAlert();
	const { contentLoading } = useSelector((state) => state.common);
    const dispatch = useDispatch();
	const [questionrows, setQuestionRows] = useState([]);
	const [documentrows, setDocumentRows] = useState([]);
	const [submitcontrol, setSubmitControle] = useState({ status: 'inactive', description: __trans('submit_button') });
	const [form, setForm] = useState({ name: '',  name_error: '', description: '', sign_document: 1, question_template: 1, question_template_error: '',document_template: 1, document_template_error: '', question_template_id: '', documents_template_id: ''});
	
	const resetSubmitControlStatus = () => {
		setSubmitControle({...submitcontrol, status: 'inactive', description: __trans('submit_button')})
	};
	
	const getFormData = async () => {
		if(id !== '0'){
			await makeRequest("request-package/form/"+id, {}, 'POST', addError)
				.then((res) => {
					if (res.type === "success") {
						setForm({ ...form, name: res.data.name, description: res.data.description, sign_document: res.data.sign_document, 
							question_template_id: res.data.question_template_id === null ? '' : res.data.question_template_id, 
							question_template: res.data.question_template_id === null ? 0 : 1, 
							documents_template_id: res.data.documents_template_id === null ? '' : res.data.documents_template_id, 
							document_template: res.data.documents_template_id === null ? 0 : 1
						});
						
					} 
				})
				.catch((error) => {
					
				});
		}
	}
	
	const getOptionList = async () => {
		await makeRequest('template/get-firm-question-document-list', {}, 'POST', addError)
		  .then((res) => {
			if (res.type === "success") {
				setQuestionRows(res.ques_data)
				setDocumentRows(res.doc_data)
			} 
		  }).catch((error) => {
				
		  });
	}
	  
	useEffect(() => {
		if(open === true){
			getFormData();
			getOptionList();
		}
	}, [open])
	
	const valueChanged = (e) => {
		const { name, value } = e.target;
		let newEdit = { ...form };
		newEdit[name] = value;
		newEdit[name + '_error'] = '';
		setForm(newEdit);
	}
	
	const checkboxChange = (e) => {
		//console.log('checkboxChange e', e)
		const name = e.target.name;
		let newEdit = { ...form };
		if(e.target.checked){
			newEdit[name] = 1;
		}else{
			newEdit[name] = 0;
			newEdit[name + '_error'] = '';
			if(name === 'question_template'){
				newEdit['question_template_id'] = '';
			}else if(name === 'document_template'){
				newEdit['documents_template_id'] = '';
			}
		}
		setForm(newEdit);
	}
	
	const validateForm = () => {
		let newEdit = { ...form };
		let validateData = true;
		
		if (helpers.isEmpty(form.name)) {		
			newEdit.name_error = __trans('error_msg.template_name_error');
			validateData = false;
		} else {
			newEdit.name_error = '';
		}
		
		if (form.question_template === 1) {
			if(form.question_template_id === ''){
				newEdit.question_template_error = __trans('error_msg.field_required_error');
				validateData = false;
			}
		} else {
			newEdit.question_template_error = '';
		}
		
		if (form.document_template === 1) {
			if(form.documents_template_id === ''){
				newEdit.document_template_error = __trans('error_msg.field_required_error');
				validateData = false;
			}
		} else {
			newEdit.document_template_error = '';
		}
		
		setForm(newEdit);
		return validateData;
	}
	
	const handleSubmit = (e) => {
		if (validateForm()) {
			submitForm({ name: form.name.trim(), description: form.description.trim(), sign_document: form.sign_document, question_template_id: form.question_template_id, documents_template_id: form.documents_template_id })
		}
	}

	const submitForm = async (formData) => {
		//console.log('formData', formData);
		var submit_url = id === '0' ? 'request-package/create' : 'request-package/update/' + id;
		await makeRequest(submit_url, formData, 'POST', addError)
			.then((res) => {
				if (res.type === "success") {
					dispatch(callCommonAction({ reloaddata: true }));
					handleCancel();
				}
			}).catch((error) => {
				
			});
	}
	
	const handleCancel = () => {
		setForm({ name: '',  name_error: '', description: '', sign_document: 1, question_template: 1, question_template_error: '',document_template: 1, document_template_error: '', question_template_id: '', documents_template_id: ''})
		setOpen(false)
	}
	
	//console.log('form data', form.document_template)
	
    return (
		<Modal className="header-border two-buttons" size="sm" open={open} onRequestClose={() => handleCancel()} 
			modalLabel="" 
			modalHeading={id === '0' ? __trans('create_package_request_title') : __trans('update_package_request_title')}
			preventCloseOnClickOutside={true}
			primaryButtonText={__trans('submit_button')} 
			secondaryButtonText={__trans('cancel_button')} 
			onRequestSubmit={() => handleSubmit() }
			loadingStatus={submitcontrol.status} 
			loadingDescription={submitcontrol.description} 
			onLoadingSuccess={resetSubmitControlStatus}
		>
		{!contentLoading ?
			<Form aria-label="create/update template form" className="">
				<Stack gap={5}>
					<TextInput type="text" name="name" id="name" 
						value={form.name}
						onChange={valueChanged} 
						labelText={__trans('forms.template_name')} 
						placeholder={__trans('forms.template_name_placeholder')} 
						invalid={form.name_error !== '' ? true : false}
						invalidText={form.name_error}
					/>
					<TextArea id="description" name="description"
						enableCounter={true}
						maxCount={100}
						value={form.description}
						onChange={valueChanged} 
						labelText={__trans('forms.template_description')}
						placeholder={__trans('forms.template_description_placeholder')}
						rows={4}
					/>
					<div className="row">
					  <div className="col-md-6">
						<CheckboxGroup legendText={__trans('forms.request_package_incude_label')}>
						  <Checkbox className="mb-4" name="sign_document" id="sign_document"
							onChange={(e) => checkboxChange(e)}  
							checked={Number(form.sign_document) === 1 ? true : false}  
							labelText={__trans('forms.sign_document_label')}
						  />
						  <Checkbox className="mb-4" name="question_template" id="question_template"
							onChange={(e) => checkboxChange(e)}  
							checked={Number(form.question_template) === 1 ? true : false}  
							labelText={__trans('forms.complete_questionare_label')} 
						 />
						  <Checkbox className="mb-4" name="document_template" id="document_template"
							onChange={(e) => checkboxChange(e)} 
							checked={Number(form.document_template) === 1 ? true : false}  
							labelText={__trans('forms.request_files_label')} 
						 />
						</CheckboxGroup>
					  </div>
					  <div className="col-md-5">
						<Select className="pt-5 mb-2" size="sm" name="question_template_id" id="question_template_id" 
							value={form.question_template_id}
							labelText={''} 
							invalid={form.question_template_error !== '' ? true : false}
							invalidText={form.question_template_error}
							onChange={valueChanged}
						>
							<SelectItem value="" text={__trans('forms.select_template_label')} />
							{form.question_template === 1 && questionrows.length && questionrows.map((item) => (
								<SelectItem value={item._id} text={item.name} />
							))}
						</Select>
						<Select size="sm" name="documents_template_id" id="documents_template_id" 
							value={form.documents_template_id}
							labelText={''} 
							invalid={form.document_template_error !== '' ? true : false}
							invalidText={form.document_template_error}
							onChange={valueChanged}
						>
							<SelectItem value="" text={__trans('forms.select_template_label')} />
							{form.document_template === 1 && documentrows.length && documentrows.map((item) => (
								<SelectItem value={item._id} text={item.name} />
							))}
						</Select>
					  </div>
					</div>
				</Stack>
			</Form>
		:
			<div className='d-flex justify-content-center'>
				<Loading className='text-center' withOverlay={false} />
			</div>
		}
		</Modal>
    );
};
export default PackageRequestTemplate