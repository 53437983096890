import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Form, Stack, Select, SelectItem, Link, Loading, ContainedList, ContainedListItem } from '@carbon/react';
import { CheckmarkOutline, MisuseOutline, ArrowRight } from '@carbon/icons-react';
import { useSelector, useDispatch } from 'react-redux';
import useToastAlert from '../../../hooks/useToastAlert';
import { __trans, getData, userDetails } from '../../../_helpers';

const GetStarted = ({ open, setOpen, form, setForm, setNextStep }) => {
	const { addError } = useToastAlert();
	const navigate = useNavigate();
	const { contentLoading } = useSelector((state) => state.common);
    const dispatch = useDispatch();
	const [rows, setRows] = useState([]);
	const [selectedpackage, setSelectedPackage] = useState({});
	const [submitcontrol, setSubmitControle] = useState({ status: 'inactive', description: __trans('submit_button') });
	const resetSubmitControlStatus = () => {
		setSubmitControle({...submitcontrol, status: 'inactive', description: __trans('submit_button')})
	};
	
	const getPackageList = async () => {
	   await getData("request-package/firm-list", {}, 'POST', addError, dispatch)
			.then((res) => {
				if (res.type === "success") {
					setRows(res.data.docs)
					if(form.package_id !== ''){
						var rows_data = res.data.docs;
						const itemArray = rows_data.filter((item, index) => item._id === form.package_id);
						if(itemArray.length > 0){
							setSelectedPackage(itemArray[0]);
							let newEdit = { ...form };
							newEdit.sign_document         = itemArray[0].sign_document;
							newEdit.question_template_id  = itemArray[0].question_template_id !== null ? itemArray[0].question_template_id : '';
							newEdit.documents_template_id = itemArray[0].documents_template_id !== null ? itemArray[0].documents_template_id : '';
							setForm(newEdit);
						}
					}
				}
			});
	}
	  
	useEffect(() => {
		if(open){
			getPackageList();
		}
	}, [open])
	
	const valueChanged = (e) => {
		const { name, value } = e.target;
		let newEdit = { ...form };
		newEdit[name] = value;
		//set selected package
		const itemArray = rows.filter((item, index) => item._id === value);
		if(itemArray.length > 0){
			setSelectedPackage(itemArray[0]);
			newEdit.sign_document         = itemArray[0].sign_document;
			newEdit.question_template_id  = itemArray[0].question_template_id !== null ? itemArray[0].question_template_id : '';
			newEdit.documents_template_id = itemArray[0].documents_template_id !== null ? itemArray[0].documents_template_id : '';
		}
		setForm(newEdit);
	}

	const validateForm = () => {
		let newEdit = { ...form };
		let validateData = true;
		if (form.package_id === '') {
			newEdit.package_id_error = __trans('error_msg.field_required_error');
			validateData = false;
		} else {
			newEdit.package_id_error = '';
		}
		
		setForm(newEdit);
		return validateData;
	}
	
	const handleSubmit = (e) => {
		//Before submit validate form
		if (validateForm()) {
			submitForm();
		}
	}

	const submitForm = async () => {
		setNextStep(2)
	}
	
	const handleCancel = () => {
		navigate('/clients/in-progress-list');
	}
	
	const getPackageRoute = () => {
		return "/templates?tab=0&type=edit&id="+form.package_id
	}

	return (
		<Modal className="header-border two-buttons arrow-icon" size="sm" open={open} onRequestClose={() => handleCancel()} 
			modalLabel="" 
			modalHeading={__trans('get_started_title')}
			preventCloseOnClickOutside={true}
			primaryButtonText={__trans('get_started_button')} 
			secondaryButtonText={__trans('cancel_button')} 
			onRequestSubmit={() => handleSubmit() }
			loadingStatus={submitcontrol.status} 
			loadingDescription={submitcontrol.description} 
			onLoadingSuccess={resetSubmitControlStatus}
		>
		{!contentLoading ?
			<Form aria-label="create/update client form">
				<Stack>
					<div className=''>
					<p className="fs-16 mb-3">{__trans('get_started_info')}</p>
						<div className="row mb-2">
							<div className="col-md-6">
								<Select	className="mb-3" name="package_id" id="package_id"
									value={form.package_id}
									onChange={valueChanged}
									labelText={__trans('forms.select_package_label')}
									placeholder={__trans('forms.select_package_placeholder')}
									invalid={form.package_id_error !== '' ? true : false}
									invalidText={form.package_id_error}
								
								>
									<SelectItem value="" text={__trans('forms.select_package_placeholder')} />
									{rows.length && rows.map((item)=>(
										<SelectItem key={item._id} value={item._id} text={item.name} />
									))}
								</Select>
								{form.package_id !== '' ?
									<Link href={getPackageRoute()} target="_blank" renderIcon={ArrowRight}>
										{__trans('edit_request_button')}
									</Link>
								:''
								}
							</div>
							{form.package_id !== '' ? 
								<div className="col-md-6">
									<ContainedList size="sm" label={__trans('forms.request_package_incude_label')} className="mt-4" >
										<ContainedListItem renderIcon={form.sign_document === 1 ? CheckmarkOutline : MisuseOutline} className={form.sign_document === 1 ? "icon-success" : "icon-danger"}>
											{__trans('forms.sign_document_label')}
										</ContainedListItem>
										<ContainedListItem renderIcon={form.question_template_id !== '' ? CheckmarkOutline : MisuseOutline} className={form.question_template_id !== '' ? "icon-success" : "icon-danger"}>
											{__trans('forms.complete_questionare_label')}
										</ContainedListItem>
										<ContainedListItem renderIcon={form.documents_template_id !== '' ? CheckmarkOutline : MisuseOutline} className={form.documents_template_id !== '' ? "icon-success" : "icon-danger"}>
											{__trans('forms.request_files_label')}
										</ContainedListItem>
									</ContainedList>
							   </div>
							  :''
							}
						</div>
                    </div>
				</Stack>
			</Form>
			:
			<div className='d-flex justify-content-center'>
				<Loading className='text-center' withOverlay={false} />
			</div>
		}
	</Modal>
	)
}

export default GetStarted
