import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Stack, Modal, TextInput } from '@carbon/react';
import useToastAlert from '../../../hooks/useToastAlert';
import { __trans, makeRequest, userDetails } from '../../../_helpers';
import helpers from '../../../_helpers/common';

const ChangePassword = ({ open, setOpen }) => {
	const navigate = useNavigate();
	const { addError } = useToastAlert();
	const [submitcontrol, setSubmitControle] = useState({ status: 'inactive', description: __trans('submit_button') });
	const [form, setForm] = useState({ current_password: '', current_password_error: '', password: '', password_error: '',  confirm_password: '', confirm_password_error: '' });
	
	const resetSubmitControlStatus = () => {
		setSubmitControle({...submitcontrol, status: 'inactive', description: __trans('submit_button')})
	};
	
	const valueChanged = (e) => {
		const { name, value } = e.target;
		let newEdit = { ...form };
		newEdit[name] = value;
		newEdit[name + '_error'] = '';
		setForm(newEdit);
	}

	const validateForm = () => {
		let newEdit = { ...form };
		let validateData = true;
		
		if (form.current_password === '') {
			newEdit.current_password_error = __trans('error_msg.password_empty');
			validateData = false;
		} else {
			let msg = helpers.isValidFormatForPassword(form.current_password);
			newEdit.current_password_error = '';
			if (msg !== '') {
				newEdit.current_password_error = msg;
				validateData = false;
			}
		}
		if (form.password === '') {
			newEdit.password_error = __trans('error_msg.password_empty');
			validateData = false;
		} else {
			let msg = helpers.isValidFormatForPassword(form.password);
			newEdit.password_error = '';
			if (msg !== '') {
				newEdit.password_error = msg;
				validateData = false;
			}
		}
		if (form.confirm_password === '') {
			newEdit.confirm_password_error = __trans('error_msg.password_empty');
			validateData = false;
		} else {
			newEdit.confirm_password_error = '';
			let msg = helpers.isValidFormatForPassword(form.confirm_password);
			if (msg !== '') {
				newEdit.confirm_password_error = msg;
				validateData = false;
			} else {
				if (form.confirm_password !== form.password) {
					newEdit.confirm_password_error = __trans('error_msg.confirm_pass_match_paas');
					validateData = false;
				}
			}
		}
		setForm(newEdit);
		return validateData;
	}
	
	const handleSubmit = (e) => {
		//Before submit validate form
		if (validateForm()) {
			submitForm({ current_password: form.current_password, password: form.password, confirm_password: form.confirm_password })
		}
	}

	const submitForm = async (formData) => {
		setSubmitControle({...submitcontrol, status: 'active'})
		var user_id = userDetails('id');
		await makeRequest("users/update-password/" + user_id, formData, 'POST', addError)
			.then((res) => {
				setSubmitControle({...submitcontrol, status: 'finished'})
				if (res.type === "success") {
					setForm({ current_password: "", current_password_error: "",  password: '', password_error: '',  confirm_password: '', confirm_password_error: '' });
					setTimeout(() => {
						setOpen(false)
						handleLogout();
					}, 2000);
				} 
			}).catch((error) => {
				setSubmitControle({...submitcontrol, status: 'finished'})
			});
	}
	
	const handleLogout = () => {
		localStorage.clear();
		navigate("/login");
	}
	
	const handleCancel = () => {
		setForm({ current_password: "", current_password_error: "", password: '', password_error: '',  confirm_password: '', confirm_password_error: '' });
		setOpen(false)
	}
	
    return (
        <Modal className="header-border" open={open} onRequestClose={() => handleCancel()} 
			modalHeading={__trans('change_password_page_heading')}
			preventCloseOnClickOutside={true}
			primaryButtonText={__trans('submit_button')} 
			secondaryButtonText={__trans('cancel_button')} 
			onRequestSubmit={() => handleSubmit() }
			loadingStatus={submitcontrol.status} 
			loadingDescription={submitcontrol.description} 
			onLoadingSuccess={resetSubmitControlStatus}
		>
		<Stack gap={5}>
		<TextInput type="password" name="current_password" 
			value={form.current_password}
			id="current_password"
			onChange={valueChanged}
			labelText={__trans('forms.current_password_label')}
			placeholder={__trans('forms.current_password_placeholder')}
			invalid={form.current_password_error !== '' ? true : false}
			invalidText={form.current_password_error}
		/>
		<TextInput type="password" name="password" id="password" 
			value={form.password}
			onChange={valueChanged}
			labelText={__trans('forms.password_label')}
			placeholder={__trans('forms.password_placeholder')}
			invalid={form.password_error !== '' ? true : false}
			invalidText={form.password_error}
		/>
		<TextInput type="password" name="confirm_password" id="confirm_password" 
			value={form.confirm_password}
			onChange={valueChanged}
			labelText={__trans('forms.confirm_password_label')}
			placeholder={__trans('forms.confirm_password_placeholder')}
			invalid={form.confirm_password_error !== '' ? true : false}
			invalidText={form.confirm_password_error}
		/>
		</Stack>
      </Modal>
    );
};

export default ChangePassword;
