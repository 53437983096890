import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import moment from "moment";
import useToastAlert from '../../../hooks/useToastAlert';
import { makeRequest } from '../../../_helpers'
import GetStarted from './GetStarted';
import SelectClient from './SelectClient';
import UploadDocument from './UploadDocument';
import PrepareDocusealContract from './PrepareDocusealContract';
import QuestionAnswerPreview from './QuestionAnswerPreview';
import DocumentPreview from './DocumentPreview';
import ReviewDelivery from './ReviewDelivery';

import DeleteConfirmation from '../../common/DeleteConfirmation';

const NewRequest = () => {
	const { id }  = useParams();
	const { addError } = useToastAlert();
	const [deleteitem, setDeleteItem]     = useState(false);
	const [deletemodule, setDeleteModule] = useState('');
	const [itemid, setItemId]             = useState('0');
	const [currentstep, setCurrentStep]   = useState(1);
	const [getstarted, setGetStarted]     = useState(false);
	const [selectclient, setSelectClient] = useState(false);
	const [uploaddoc, setUploadDoc]       = useState(false);
	const [preparecontract, setPrepareContract] = useState(false);
	const [previewquestion, setPreviewQuestion] = useState(false);
	const [previewdocument, setPreviewDocument] = useState(false);
	const [reviewdelivery, setReviewDelivery]   = useState(false);
	const [form, setForm] = useState({client_request_id: '', package_id: '', package_id_error: '', sign_document: 0, question_template_id: '', documents_template_id: '', client_id: '', client_id_error: '', email: '', email_error: '', expire_at: '', expire_at_error: '', message_to_client: '', notification_on_task: 0, notification_on_all: 0, send_reminder: 0, reminder_before_days: 1, send_request: false});
	
	const getFormData = async() => {
		await makeRequest("firm/get-client-request/" + id, {}, 'POST', addError)
			.then((res) => {
				//console.log('request data res ', res)
				if (res.type === "success") {
					let expire_date = res.data.expire_at !== null ? moment(res.data.expire_at).format("MM/DD/YYYY") : '';
					let que_temp_id = res.data.question_template_id !== null ? res.data.question_template_id : '';
					let doc_temp_id = res.data.documents_template_id !== null ? res.data.documents_template_id : '';
					setForm({ ...form, client_request_id: res.data._id, package_id: res.data.package_id, sign_document: res.data.sign_document, question_template_id: que_temp_id, documents_template_id: doc_temp_id, client_id: res.data.client_id, email: res.data.email, expire_at: expire_date, message_to_client: res.data.message_to_client, notification_on_task: res.data.notification_on_task, notification_on_all: res.data.notification_on_all, send_reminder: res.data.send_reminder, reminder_before_days: res.data.reminder_before_days })
				}
			});
	}
	
	useEffect(() => {
		if(id !== '0'){
			getFormData();
		}
		setGetStarted(true);
	}, [])
	
	useEffect(() => {
	  if(currentstep === 2){
		  setGetStarted(false);
		  setSelectClient(true);
	  }else if(currentstep === 3){
		  setSelectClient(false);
		  setUploadDoc(true);
	  }else if(currentstep === 4){
		  setUploadDoc(false);
		  setPrepareContract(true);
	  }else if(currentstep === 5){
		  setPrepareContract(false);
		  setPreviewQuestion(true);
	  }else if(currentstep === 6){
		  setPreviewQuestion(false);
		  setPreviewDocument(true);
	  }else if(currentstep === 7){
		  setPreviewDocument(false);
		  setReviewDelivery(true);
	  }
	}, [currentstep])
	
	const handleDeleteFunction = (item_id, module) => {
		setItemId(item_id);
		setDeleteModule(module);
		setDeleteItem(true);
	}
	
	//console.log('new request form', form)
    return (
        <>
		<GetStarted open={getstarted} setOpen={setGetStarted} form={form} setForm={setForm} setNextStep={setCurrentStep}/>
		<SelectClient open={selectclient} setOpen={setSelectClient} form={form} setForm={setForm} setNextStep={setCurrentStep}/>
		<UploadDocument open={uploaddoc} setOpen={setUploadDoc} form={form} setForm={setForm} setNextStep={setCurrentStep} handleDelete={handleDeleteFunction}/>
		<PrepareDocusealContract open={preparecontract} setOpen={setPrepareContract} form={form} setForm={setForm} setNextStep={setCurrentStep}/>
		<QuestionAnswerPreview open={previewquestion} setOpen={setPreviewQuestion} form={form} setForm={setForm} setNextStep={setCurrentStep}/> 
		<DocumentPreview open={previewdocument} setOpen={setPreviewDocument} form={form} setForm={setForm} setNextStep={setCurrentStep}/> 
		<ReviewDelivery open={reviewdelivery} setOpen={setReviewDelivery} form={form} setForm={setForm} setNextStep={setCurrentStep}/> 
		{/* Delete Confirmation popup */}
		<DeleteConfirmation open={deleteitem} setOpen={setDeleteItem} module={deletemodule} id={itemid}/>
		</>
    );
};

export default NewRequest