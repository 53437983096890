import React, { useState } from 'react';
import { Modal } from 'carbon-components-react';
import { useDispatch } from 'react-redux';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import useToastAlert from '../../../hooks/useToastAlert'
import { __trans, makeRequest } from '../../../_helpers'

const DeleteClientConfirmation = ({ open, setOpen, id, clientname }) => {
	
	const { addError } = useToastAlert();
	const dispatch = useDispatch();
	const [submitcontrol, setSubmitControle] = useState({ status: 'inactive', description: __trans('deleting_button') });
	
	const resetStatus = () => {
		setSubmitControle({...submitcontrol, status: 'inactive', description: __trans('deleting_button')})
	};
	
	const handleDeleteSelected = async (id) => {
		setSubmitControle({...submitcontrol, status: 'active'})
		//console.log('end_url', end_url);
		await makeRequest('users/client-delete', {item_id: id}, 'POST', addError)
			.then((res) => {
				setSubmitControle({...submitcontrol, status: 'finished'})
				dispatch(callCommonAction({ reloaddata: true }));
				if (res.type === "success") {
					//setTimeout(() => {
						setOpen(false)
					//}, 2000);
				} 
			}).catch((error) => {
				setSubmitControle({...submitcontrol, status: 'finished'})
			});
	}
	
	return (
	   <Modal className="header-border two-buttons" size="sm" open={open} onRequestClose={() => setOpen(false)}
			modalHeading={__trans('delete_client_header')} 
			modalLabel="" 
			preventCloseOnClickOutside={true}
			primaryButtonText={__trans('delete_confirmation_button')} 
			secondaryButtonText={__trans('cancel_delete_confirmation_button')} 
			onRequestSubmit={() => handleDeleteSelected(id)}
			loadingStatus={submitcontrol.status} 
			loadingDescription={submitcontrol.description} 
			onLoadingSuccess={resetStatus}
	   >
	   <p>{__trans('delete_client_info', {'client_name': clientname})}</p>
	   </Modal>
	);
};

export default DeleteClientConfirmation;