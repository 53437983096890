// src/common/hooks/useAPIError/index.js
import { useContext } from 'react'
import {ToastAlertContext} from '../provider/ToastAlertProvider'

function useToastAlert() {
  const { error, addError, removeError } = useContext(ToastAlertContext)
  //console.log('hook error', error)
  return { error, addError, removeError }
}

export default useToastAlert