import { useNavigate } from "react-router-dom";
import { OverflowMenu, OverflowMenuItem, PaginationNav } from '@carbon/react';
import { TabsSkeleton } from 'carbon-components-react';
import { Document } from '@carbon/icons-react';
import { __trans, userDetails } from '../../../../_helpers'

const TabContent = ({rows, contentLoading, module, onEdit, onDelete, onPageChange, paginationdata}) => {
	const navigate = useNavigate();
	const handleItemEdit = (item_id, module) => {
		if(module === 'question'){
			navigate("/template/create-update/" + item_id + '?back_to=templates?tab=1')
		}else{
			onEdit(item_id, module)
		}
	}
	
	const handleItemDelete = (item_id, module) => {
		onDelete(item_id, module)
	}
	
  /* eslint-disable no-script-url */
  return (
    <>
		{!contentLoading ? 
			<div className="">
				{rows.length > 0 ? 
					<div className="row row-cols-2 row-cols-md-6" style={{minHeight: '450px'}}>
						{rows.map((item, index) => (
							<div className="col mb-5">
								<div className="temp-list-item">
									<div className="temp-img">
										<Document width={'47px'} height={'47px'} className="prince-50" />
										{userDetails('id') === item.created_by ? 
											<OverflowMenu flipped={document?.dir==='rtl' } aria-label="overflow-menu">
												<OverflowMenuItem itemText={__trans('edit_button')} onClick={() => handleItemEdit(item._id, module)} />
												<OverflowMenuItem itemText={__trans('delete_button')} onClick={() => handleItemDelete(item._id, module)}  />
											</OverflowMenu>
											:''
										}
									</div>
									<h6>{item.name}</h6>
									<div className="small">{item.updated_time}</div>
								</div>
							</div>
						))}
					</div>
					:
					<div className="" style={{height: '300px',display: 'flex',justifyContent: 'center',alignItems: 'center',}}>
						<p className="d-flex justify-content-center">{__trans('common.table_no_data_found')}</p> 
					</div>
				}
				{paginationdata.total_pages > 1 ?
					<div className="d-flex justify-content-center py-0 rounded-1">
						<PaginationNav itemsShown={5} totalItems={paginationdata.total_pages} page={paginationdata.currentpage} onChange={(e) => onPageChange(e)}/>
					</div>
				:''
				}
			</div>
		  :
			<div style={{ maxWidth: '100%' }}>
				<TabsSkeleton count={8}/>
			</div>
		}
    </>
  );
};

export default TabContent;