import React, { Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { callCommonAction } from './redux/Common/CommonReducer';
import useToastAlert from './hooks/useToastAlert';
import { __trans, makeRequest } from './_helpers';
import './globals.scss';
import './theme.css';
import './common.css';
import './styles.css';

// Containers
import DefaultLayout from './components/layout/DefaultLayout';
// Pages
import Login from './components/auth/login/Login';
import SendVerificationCode from './components/auth/verification/SendVerificationCode';
import VerifyOTP from './components/auth/verification/VerifyOTP';
import BuySubscription from './components/firm/subscription/BuySubscription';
import ForgotPassword from './components/auth/forgot-password/ForgotPassword';
import ResetPassword from './components/auth/reset-password/ResetPassword';
import Register from './components/auth/register/Register';
import TermAndCondition from './components/pages/term-condition/TermAndCondition';
import ContactUs from './components/pages/contact-us/ContactUs';
import PrivacyPolicy from './components/pages/privacy-policy/PrivacyPolicy';
import ClientWelcome from './components/client/account-setup/ClientWelcome';
import ClientAccountSetup from './components/client/account-setup/ClientAccountSetup';

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
)

const App = () => {
	const { addError } = useToastAlert();
	const dispatch = useDispatch();
	const { isLoggedIn, isLoggedInVerified, user } = useSelector((state) => state.common);
	
	/** Only Load Time Set State From LocalStorage */
	useEffect(() => {
		let raiseNotificationBell = localStorage.raiseNotificationBell != undefined ? localStorage.raiseNotificationBell == "true" ? true : false : false;
		if (localStorage.token !== undefined && localStorage.token !== null) {
			dispatch(callCommonAction({
				isLoggedIn: true,
				token: localStorage.token,
				user: JSON.parse(localStorage.user),
				subscription: localStorage.subscription === undefined ? null : JSON.parse(localStorage.subscription),
				isSubscriptionCanceled: localStorage.isSubscriptionCanceled === undefined ? false : localStorage.isSubscriptionCanceled === false ? false : true
			}));
		}
		//without user logged in we also need raise Notification Bell
		dispatch(callCommonAction({ raiseNotificationBell: raiseNotificationBell }));
	}, [isLoggedIn, isLoggedInVerified]);

	useEffect(() => {
		if (localStorage.token !== undefined && localStorage.token !== null) {
			checkUserExist();
		}
		//set page title
		document.title = __trans('page_title');
	}, []);
	
	
	const checkUserExist = async () => {
		let userData = JSON.parse(localStorage.user)
		try {
			const res = await makeRequest(`users/detail/${userData.id}`, {},  'POST', addError);
			if (res.data === null) {
				//localStorage.removeItem("token");
				//localStorage.removeItem('user');
				//.removeItem('subscription');
				dispatch(callCommonAction({ isLoggedIn: false, token: null, user: null }));
				localStorage.clear();
			}
		} catch (error) {
			addError(error.message, 'error')
		}
	};
  
	return (
		<BrowserRouter>
			<Suspense fallback={loading}>
				<Routes>
					<Route path="/" name="Home" element={<Login />} />
					<Route exact path="/login" name="Login Page" element={<Login />} />
					<Route exact path="/send-verification-code" name="Send Verification Code Page" element={<SendVerificationCode />} />
					<Route exact path="/verify-otp" name="Verify OTP Page" element={<VerifyOTP />} />
					<Route exact path="/buy-subscription" name="Buy Subscription Page" element={<BuySubscription />} />
					<Route exact path="/client-welcome" name="Client Welcome Page" element={<ClientWelcome />} />
					<Route exact path="/client-account-setup" name="Client Account Setup" element={<ClientAccountSetup />} />
					//<Route exact path="/register" name="Register Page" element={<Register />} />
					<Route exact path="/register-firm/:token" name="Register Page" element={<Register />} />
					<Route exact path="/forgot-password" name="Forgot Password" element={<ForgotPassword />} />
					<Route exact path="/reset-password/:token" name="Reset Password" element={<ResetPassword />} />
					<Route exact path="/contact-us" name="Contact Us" element={<ContactUs />} />
					<Route exact path="/term-and-conditions" name="Term And Condition" element={<TermAndCondition />} />
					<Route exact path="/privacy-policy" name="Privacy Policy" element={<PrivacyPolicy />} />
					<Route path="*" name="Home" element={<DefaultLayout />} />
				</Routes>
			</Suspense>
		</BrowserRouter>
	)
}

export default App
