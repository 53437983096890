import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Stack } from '@carbon/react';
import { Modal, Form, TextInput, TextArea, Loading, ContainedList, ContainedListItem } from 'carbon-components-react';
import { Edit, Restart } from '@carbon/icons-react';
import { useSelector, useDispatch } from 'react-redux';
import useToastAlert from '../../../hooks/useToastAlert'
import { __trans, getData, makeRequest } from '../../../_helpers'

const DocumentPreview = ({ open, setOpen, id, form, setForm, setNextStep }) => {
	const { addError } = useToastAlert();
	const navigate = useNavigate();
	const { contentLoading } = useSelector((state) => state.common);
    const dispatch = useDispatch();
	const [optionrows, setOptionRows] = useState([]);
	const [submitcontrol, setSubmitControle] = useState({ status: 'inactive', description: __trans('submit_button') });
	const [formdata, setFormData] = useState({ name: '',  name_error: '', description: '' });
	
	const resetSubmitControlStatus = () => {
		setSubmitControle({...submitcontrol, status: 'inactive', description: __trans('submit_button')})
	};
	
	const getFormData = async () => {
		if(form.documents_template_id !== ''){
			await getData("template/form/"+form.documents_template_id, {}, 'POST', addError, dispatch)
				.then((res) => {
					if (res.type === "success") {
						setFormData({ ...formdata, type: res.data.type, name: res.data.name, description: res.data.description });
					} 
				})
				.catch((error) => {

				});
		}
	}
	  
	const getDocumentData = async () => {
		if(form.documents_template_id !== ''){
			await getData("template/get-template-question/"+form.documents_template_id, {}, 'POST', addError, dispatch)
			.then((res) => {
				if (res.type === "success") {
					var current_document = res.documents[0].documents;
					setOptionRows(current_document);
					var current_selection = [];
					for (const item of current_document) {
						current_selection.push(item._id)
					}			
				} 
			})
			.catch((error) => {

			});
		}
	}
	  
	useEffect(() => {
		if(open === true){
			//if(id !== '0'){
				getFormData();
				getDocumentData();
			//}
		}
	}, [open])
	
	const valueChanged = () => {
		console.log('value changed');
	}
	
	const handleSubmit = (e) => {
		setNextStep(7);
	}
	
	const saveAndClose = async() => {
		var formData = new FormData();
		formData.append(`package_id`, form.package_id)
		formData.append(`client_id`, form.client_id)
		formData.append(`sign_document`, form.sign_document)
		formData.append(`question_template_id`, form.question_template_id)
		formData.append(`documents_template_id`, form.documents_template_id)
		
		setSubmitControle({...submitcontrol, status: 'active'})
		let end_url = form.client_request_id === '' ? 'firm/create-client-request' : 'firm/update-client-request/'+form.client_request_id;
		await makeRequest(end_url, formData, 'POST', addError)
			.then((res) => {
				setSubmitControle({...submitcontrol, status: 'finished'})
				if (res.type === "success") {
					navigate('/clients/in-progress-list');
				} 
			})
			.catch((error) => {
				setSubmitControle({...submitcontrol, status: 'finished'})
			});
	}

	const handleCancel = () => {
		navigate('/clients/in-progress-list');
	}
	
	const getEditRoute = () => {
		return "/templates?tab=2&type=edit&id="+form.documents_template_id;
	}
	
    return (
        <Modal className="header-border two-buttons arrow-icon" open={open} onRequestClose={() => handleCancel()} 
			modalHeading={__trans('preview_document_template')} 
			modalLabel=""
			preventCloseOnClickOutside={true}
			primaryButtonText={__trans('continue_button')} 
			secondaryButtonText={__trans('save_and_close_button')} 
			onSecondarySubmit={() => saveAndClose()}
			onRequestSubmit={() => handleSubmit() }
			loadingStatus={submitcontrol.status} 
			loadingDescription={submitcontrol.description} 
			onLoadingSuccess={resetSubmitControlStatus}
		>
		
		{form.documents_template_id !== '' ?
			<>
			{!contentLoading ?
				<>
				{form.documents_template_id !== '' ?
					<div className="row mb-1">
						<div className="col-md-12 d-flex gap-2 justify-content-end">
							<Link to={getEditRoute()} target="_blank" type="button" className="btn btn-outline-primary btn-sm cstm-btn-small">
								{__trans('edit_button')} <Edit />
							</Link>
							<button onClick={() => getDocumentData()} type="button" className="btn btn-sm cstm-btn-small btn-outline-secondary">
								{__trans('refresh_button')} <Restart />
							</button>
						</div>
					</div>
					:''
				}		
				<Form aria-label="create/update template form" className="">
					<Stack gap={5}>
						<TextInput type="text" name="name" id="template_name" 
							readOnly={true}
							value={formdata.name}
							onChange={valueChanged} 
							labelText={__trans('forms.template_name')} 
							placeholder={__trans('forms.template_name_placeholder')} 
							invalid={formdata.name_error !== '' ? true : false}
							invalidText={formdata.name_error}
						/>
						<div className="row">
							<div className="col-6">
								<ContainedList className="bg-body border p-3" label={__trans('forms.document_request_list')} kind="on-page" size="sm" action={''}>
									{optionrows.length && optionrows.map((item) => (
									  <ContainedListItem key={item._id}>{item.name}</ContainedListItem>
									))}
								</ContainedList>
							</div>
							<div className="col-6">
								<TextArea id="document_description" name="description"
									readOnly={true}
									value={formdata.description}
									onChange={valueChanged} 
									labelText={__trans('forms.template_description')}
									placeholder={__trans('forms.template_description_placeholder')}
									rows={6}
								/>
							</div>
						</div>
					</Stack>
				</Form>
				</>
				
			:
				<div className='d-flex justify-content-center'>
					<Loading className='text-center' withOverlay={false} />
				</div>
			}
			</>
		:
			<div className='text-center my-5'>
				<h5>{__trans('no_document_asked')}</h5>
			</div>
		}
		</Modal>
    );
};
export default DocumentPreview