import React, {useEffect, useState} from 'react';
import { ContainedList, ContainedListItem } from '@carbon/react';
import { Tile, TileAboveTheFoldContent } from 'carbon-components-react';
import { Checkmark } from '@carbon/icons-react';
import { useNavigate } from "react-router-dom";
import ToastNotification from "../../common/ToastNotification";
import useToastAlert from '../../../hooks/useToastAlert'
import helpers from '../../../_helpers/common';
import { __trans, makeRequest, userDetails, userSubscriptionStatus } from '../../../_helpers';
import WebLayout from "../../layout/WebLayout";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PayPalButton from './paypal-button/PayPalButton';

const CLIENTID = helpers.paypalDetail('CLIENTID');
//console.log('CLIENTID', CLIENTID);

const initialOptions = {
    clientId: CLIENTID,
    currency: "USD",
    intent: "capture",
};

const BuySubscription = () => {
	const { addError } = useToastAlert();
	const navigate = useNavigate();
	const [plans, setPlans] = useState({});
	
	useEffect(() => {
		//Check if user token exist then redirect him to dashboard
		checkUserSubscription();
	}, [])
	
	const checkUserSubscription = () => {
		if(localStorage.getItem("token") === null){
			navigate('/login');
		}
		if(userDetails('role') !== 'Admin' && !userSubscriptionStatus()){
			getPlansList();
		}else{
			navigate('/clients/in-progress-list');
		}
	}

	const getPlansList = async () => {
		await makeRequest("subscription_plan/list", {}, 'POST', addError)
		  .then((res) => {
			//console.log('response', res)
			if (res.type === "success") {
			  setPlans(res.data.docs)
			}
		  }).catch((error) => {
			
		  });
	  }

	const prepareFeatureList = (plan) => {
		var features = JSON.parse(plan.features);
		return Object.entries(features).map(([key,value],i) => <ContainedListItem renderIcon={Checkmark}>{value}</ContainedListItem>)
	}
	//console.log('plans', plans)
	return (
	<WebLayout>
		<div className="container">
			<div className="col-12 mb-5 text-center">
				<h1 className="h1">{__trans('subscribe_plan_page_heading')}</h1>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="row">
						<PayPalScriptProvider options={initialOptions}>
							{plans.length && plans.map((plan, index) => (
								<div className="col-md-4 mb-3">
									<Tile className="rounded border plan-item">
										<TileAboveTheFoldContent>
											<h4>{plan.name}</h4>							  
											<p className="fs-15 fw-bold mb-2 text-primary">{plan.fullprice}</p>
											<p className="fs-15 fw-bold mb-2 text-primary">{plan.client_limit}/{__trans('client_limit')}</p>
											<p className="mb-3">{plan.description}</p>
										</TileAboveTheFoldContent>
										<div className="mb-3">
											<ContainedList label="Features" kind="disclosed">
												{ prepareFeatureList(plan) }
											</ContainedList>
										</div>
										<PayPalButton planID={plan._id} itemprice={plan.price}/>
									</Tile>
								</div>
							))}
						</PayPalScriptProvider>
					</div>
				</div>
			</div>
		</div>
	</WebLayout>
	)
}

export default BuySubscription