import { NavLink } from "react-router-dom";
import { __trans } from '../../../_helpers'

const ClientRightNav = () => {
	
	return (
			<ul className="sidebar-links">
				<li>
					<NavLink to="document-list" activeclassname="active">
						<svg width="16" height="16" viewBox="0 0 15 15" fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<rect width="16" height="16" fill="white" fillOpacity="0.01" style={{mixBlendMode: 'multiply'}} />
							<path d="M12.85 4.65L9.35 1.15C9.25 1.05 9.15 1 9 1H4C3.45 1 3 1.45 3 2V14C3 14.55 3.45 15 4 15H12C12.55 15 13 14.55 13 14V5C13 4.85 12.95 4.75 12.85 4.65ZM9 2.2L11.8 5H9V2.2ZM12 14H4V2H8V5C8 5.55 8.45 6 9 6H12V14Z" fill="#fff" />
							<path d="M11 11H5V12H11V11Z" fill="#fff" />
							<path d="M11 8H5V9H11V8Z" fill="#fff" />
						</svg> 
						{__trans('admin_sidebar.submitted_document_list')}
					</NavLink>
				</li>
			</ul>
	)
}

export default ClientRightNav
