import axios from 'axios';
import { callCommonAction } from '../redux/Common/CommonReducer';
import { __trans } from './index';
const API_ENDPOINT = process.env.REACT_APP_ENDPOINT;
var axiosInstances = {};

export function makeRequest(path, data = {}, method = 'POST', addError) {
	cancelPreviousRequest(path);
	const url_complete = API_ENDPOINT + path;
	var instance = getInstance(path);
	const requestOptions = { method: method, url: url_complete, data: data, headers: authHeader() };
	return instance(requestOptions)
			.then(response => {
				if(response.data.type === 'unauthorized'){
					localStorage.clear();
					window.location.href = '/login';
				}else if (response.data.type === "success") {
					if(response.data.message !== ''){
						let ln_msg = __trans('server_msg.'+response.data.message);
						addError(ln_msg, response.data.type)
					}
				} else if (response.data.type === "validation_error") {
					const validationError = response.data.data.errors;
					if (validationError && Object.keys(validationError).length > 0) {
						const firstErrorKey = Object.keys(validationError)[0];
						const firstErrorMessage = validationError[firstErrorKey][0];
						addError(firstErrorMessage, 'error')
					} else {
						let ln_msg = __trans('server_msg.'+response.data.message);
						addError(ln_msg, 'error')
					}
				}else if (response.data.type === "server_error") {
					addError(response.data.message, 'error')
				} else {
					let ln_msg = __trans('server_msg.'+response.data.message);
					addError(ln_msg, 'error')
				}
				return response.data;
			}).catch((error) => {
				if (axios.isCancel(error)) {
					console.error('axios.isCancel', error.message);
					return {};
				}else{
					let ln_msg = __trans('server_msg.server_error');
					addError(ln_msg, 'error')
				}
			});
}

export function getData(path, data = {}, method = 'POST', addError, dispatch) {
	dispatch(callCommonAction({ contentLoading: true }));
	const url_complete = API_ENDPOINT + path;
	const requestOptions = { method: method, url: url_complete, data: data, headers: authHeader() };
	return axios(requestOptions)
        .then(response => {
			dispatch(callCommonAction({ contentLoading: false, reloaddata: false }));
			if(response.data.type === 'unauthorized'){
				localStorage.clear();
				window.location.href = '/login';
			}else if (response.data.type === "error") {
				let ln_msg = __trans('server_msg.'+response.data.message);
				addError(ln_msg, 'error')
			}else if (response.data.type === "server_error") {
				addError(response.data.message, 'error')
			}
			return response.data;
		}).catch((error) => {
			dispatch(callCommonAction({ contentLoading: false, reloaddata: false }));
			let ln_msg = __trans('server_msg.server_error');
			addError(ln_msg, 'error')
		});
}



function authHeader(){
	const authtoken = localStorage.getItem('token');
	const lang      = localStorage.getItem('lang');
	const active_lang = lang !== 'undefined' ? lang : 'sp';
    if (authtoken !== 'undefined') {
        return { 'x-access-token': authtoken, 'x-content-language': active_lang };
    } else {
        //return {'x-access-token': 'jhgjsdgjfgjsfsjdsgfhsdghjsdfgjfsfsdfgsdfsdfj', 'x-content-language': active_lang};
        return {};
    }
}

function getInstance(pathname){
	if(axiosInstances[pathname] && !axiosInstances[pathname].controller.signal.aborted){
		return axiosInstances[pathname].instance;
	}else{
		const customAxiosInstance = createInstance();
		axiosInstances[pathname] = customAxiosInstance;
		return axiosInstances[pathname].instance;
	}
}

function createInstance(){
	const controller = new AbortController();
	const customAxiosInstance = {controller, instance: axios.create({signal: controller.signal})};
	return customAxiosInstance;
}

function cancelPreviousRequest(pathname){
	axiosInstances[pathname]?.controller.abort();
}