import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    raiseNotificationBell: false,
    isLoggedIn: false,
    isLoggedInVerified: false,
    loading: false,
    contentLoading: false,
    reloaddata: false,
    toastMsg: '',
    toastType: '',
    user: null,
	verification_otp_on: '',
	role: '',
    token: null,
    isAdmin: false,
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        callCommonAction: (state, action) => {
            return state = { ...state, ...action.payload };
        }
    },
});

// Action creators are generated for each case reducer function
export const { callCommonAction } = commonSlice.actions;

export default commonSlice.reducer;