import React, { useState, useCallback } from 'react'

export const ToastAlertContext = React.createContext({
  error: { message: '', status: '' },
  addError: () => {},
  removeError: () => {},
})

export default function ToastAlertProvider({ children }) {
  const [error, setError] = useState({ message: '', status: '' })

  const removeError = () => setError(null)

  const addError = (message, status) => setError({ message, status })

  const contextValue = {
    error,
    addError: useCallback((message, status) => addError(message, status), []),
    removeError: useCallback(() => removeError(), []),
  }

  return (
    <ToastAlertContext.Provider value={contextValue}>
      {children}
    </ToastAlertContext.Provider>
  )
}