import { TableRow, TableCell } from 'carbon-components-react';
import { __trans } from '../../_helpers';

const TableNoData = ({headers}) => {
	return (
		<TableRow className="empty-table-body">
			<TableCell colSpan={headers.length + 1}>{__trans('common.table_no_data_found')}</TableCell>
		</TableRow>
	);
};

export default TableNoData;
