import React, {useEffect, useState} from 'react';
import { Form, TextInput, Checkbox, Button, InlineLoading, InlineNotification } from '@carbon/react';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import AuthLayout from "../../layout/AuthLayout";
import useToastAlert from '../../../hooks/useToastAlert'
import helpers from '../../../_helpers/common';
import { __trans, makeRequest, checkUserLoggedIn, userDetails } from '../../../_helpers';

const Login = () => {
	const { addError } = useToastAlert();
	const [isChecked, setIsChecked] = useState(false)
	const [limiterror, setLimitError] = useState('')
	const navigate = useNavigate();
	const { loading } = useSelector((state) => state.common);
    const dispatch = useDispatch();
	const [form, setForm] = useState({ email: '', password: '', email_error: '', password_error: '' });

	useEffect(() => {
		//Check if user token exist then redirect him to dashboard
		checkUserIsLoggedIn();
	})

	const checkUserIsLoggedIn = () => {
		if(checkUserLoggedIn()){
			if(userDetails('role') !== 'Admin'){
				navigate('/clients/in-progress-list');
			}else{
				navigate('/dashboard');
			}	
		}
	}

	const valueChanged = (e) => {
		const { name, value } = e.target;
		let newEdit = { ...form };
		newEdit[name] = value;
		newEdit[name + '_error'] = '';
		setForm(newEdit);
	}

	const validateForm = () => {
		let newEdit = { ...form };
		let validateData = true;
		
		if (helpers.isEmpty(form.email)) {		
			newEdit.email_error = __trans('error_msg.email_error');
			validateData = false;
		} else {
			newEdit.email_error = '';
			if (!helpers.isValidEmail(form.email)) {
				newEdit.email_error = __trans('error_msg.email_invalid');
				validateData = false;
			}
		}
		
		if (helpers.isEmpty(form.password)) {		
			newEdit.password_error = __trans('error_msg.password_error');
			validateData = false;
		} else {
			let msg = helpers.isValidFormatForPassword(form.password);
			newEdit.password_error = '';
			if (msg !== '') {
				newEdit.password_error = msg;
				validateData = false;
			}
		}
		setForm(newEdit);
		return validateData;
	}
	
	const handleSubmit = (e) => {
		//Before submit validate form
		if (validateForm()) {
			submitForm({ email: form.email, password: form.password, remember_me: isChecked })
		}
	}

	const submitForm = async (formData) => {
		dispatch(callCommonAction({ loading: true }));
		await makeRequest("users/login", formData, 'POST', addError)
			.then((res) => {
				dispatch(callCommonAction({ loading: false }));
				if (res.type === "success") {
					localStorage.setItem('user', JSON.stringify(res.data));
					localStorage.setItem('firm', JSON.stringify(res.firm));
					dispatch(callCommonAction({ isLoggedIn: true, user: res.data, token: res.jwttoken, role: res.data.role_id }));
					navigate("/send-verification-code")
				} else if (res.type === "rate_limit_error") {
					let ln_msg = __trans('server_msg.'+res.message);
					setLimitError(ln_msg);
				} 
			}).catch((error) => {
				//console.log('error', error)
				let ln_msg = __trans('server_msg.server_error');
				addError(ln_msg, 'error')
				dispatch(callCommonAction({ loading: false }));
			});
	}
	return (
		<AuthLayout>
			<Form className="signup-form mb-3">
				{limiterror !== '' ?
					<InlineNotification
						aria-label="closes notification"
						lowContrast={true}
						kind="warning"
						onClose={function noRefCheck(){}}
						onCloseButtonClick={function noRefCheck(){}}
						statusIconDescription="notification"
						subtitle={limiterror}
						title={__trans('server_msg.warning_title')}
					/>
					:
					<div className="form-welcome-heading">
						<h2 className="mb-4">{__trans('singin_page_heading')}</h2>
					</div>
				}
				<div className="form-heading mb-5 pt-3">
					<h3 className="mb-3">{__trans('singin_page_title')}</h3>
					<p>{__trans('singin_page_text')}</p>
				</div>
				<div className="mb-4">
					<TextInput type="email" name="email" id="email" onChange={valueChanged} 
						labelText={__trans('forms.email_label')} 
						placeholder={__trans('forms.email_placeholder')}
						invalid={form.email_error !== '' ? true : false}
						invalidText={form.email_error}
					/>
				</div>
				<div className="mb-4">
					<TextInput type="password" name="password" id="password" onChange={valueChanged} 
						labelText={__trans('forms.password_label')} 
						placeholder={__trans('forms.password_placeholder')}
						invalid={form.password_error !== '' ? true : false}
						invalidText={form.password_error}
					/>
				</div>
				<div className="mt-4 mb-4">
					{!loading ? 
						<Button className="btn btn-primary" onClick={handleSubmit} size="md">{__trans('forms.login_submit_button')}</Button>
						:
						<Button kind="secondary" size="md" as="div" role="button">
							<InlineLoading className="text-info" style={{ marginLeft: '1rem' }} description="Wait..." status="active" aria-live="off"/>
						</Button>
					}	
				</div>
				<div className="d-flex justify-content-between">
					<div className="mb-5">
						<Checkbox labelText={__trans('forms.remember_me')} checked={isChecked} onChange={() => setIsChecked(!isChecked)} name="rememberMe" id="rememberMe" />
					</div>
				</div>
				{/*<div className="text-center mb-4">
					<p className="mb-0 text-muted">{__trans('login_register_info')} <Link to="/register" className="text-decoration-none text-primary"><strong>{__trans('register_link')}</strong></Link></p>
				</div>*/}
				<div className=" text-end">
					<Link to="/forgot-password" className="text-decoration-none fs-14 text-primary">{__trans('forgot_password_link')}</Link>
				</div>
			</Form>
		</AuthLayout>
	)
}

export default Login
