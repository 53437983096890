import React, { useState, useEffect } from 'react';
import { Stack, Grid, Column } from '@carbon/react';
import {Modal, TextInput, Select, SelectItem, Button, Loading } from 'carbon-components-react';
import { TrashCan as Delete, Add } from '@carbon/icons-react';
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../../../redux/Common/CommonReducer';
import useToastAlert from '../../../../hooks/useToastAlert'
import helpers from '../../../../_helpers/common';
import { __trans, makeRequest } from '../../../../_helpers'

const CreatePlan = ({ open, setOpen, id }) => {
	const { addError } = useToastAlert();
	//console.log('selected id', id);
	const { contentLoading } = useSelector((state) => state.common);
	const dispatch = useDispatch();
	const [submitcontrol, setSubmitControle] = useState({ status: 'inactive', description: __trans('submit_button') });
	const [form, setForm] = useState({ type: '',  type_error: '', name: '',  name_error: '', type_option: [], is_visible: false, is_required: false, sort: 0,});
	
	const resetSubmitControlStatus = () => {
		setSubmitControle({...submitcontrol, status: 'inactive', description: __trans('submit_button')})
	};
	
	const getFormData = async () => {
		 if(id !== '0'){
			dispatch(callCommonAction({ contentLoading: true }));
			await makeRequest("question/form/"+id, {}, 'POST', addError)
				.then((res) => {
					dispatch(callCommonAction({ contentLoading: false }));
					if (res.type === "success") {
						setForm({
							...form,
							type: res.data.type,
							type_option: res.data.question_options ? res.data.type === 'number' || res.data.type === 'text' ? [] : res.data.question_options : [],
							is_visible: res.data.is_visible,
							is_required: res.data.is_required,
							sort: res.data.sort,
							name: res.data.name
						});
					} 
				})
				.catch((error) => {
					dispatch(callCommonAction({ contentLoading: false }));
				});
		 }
	  }
	  
	useEffect(() => {
		if(open){
			getFormData();
		}
	}, [open])
	
	const valueChanged = (e) => {
		const { name, value } = e.target;
		let newEdit = { ...form };
		newEdit[name] = value;
		newEdit[name + '_error'] = '';
		setForm(newEdit);
		if (name === 'type' && (value === 'checkbox' || value === 'radio' || value === 'select')) {
			prepareTypeOptions(value);
		} 
	}
	
	/******PREPARE TYPE OPTIONS******/
	const prepareTypeOptions = (value) => {
		let optionRawData = [];
		if ((value === 'checkbox' || value === 'radio' || value === 'select')) {
			optionRawData = [{ name: value, value: '', label: '', error_name_option: '', error_name_text: '' }];
		} else {
			optionRawData = form.type_option;
		}
		setForm((prevState) => ({ ...prevState, type_option: optionRawData }));
	};
	
	const incrAndDecrOptn = (actionType, actionName, removeIndex) => {
		if (actionType === 'plus') {
			const optionRawData = {
				name: actionName, value: '', label: '', error_name_option: '', error_name_text: ''
			};
			setForm({ ...form, type_option: [...form.type_option, optionRawData] });
		} else {
			const optionRawData = form.type_option.filter((item, index) => index !== removeIndex);
			setForm({ ...form, type_option: optionRawData });
		}
	};

	const handleOptionValue = (e, index) => {
		const rawdata = [...form.type_option];
		rawdata[index].value = e.target.value;
		rawdata[index].error_name_option = ((e.target.value === '') ? rawdata[index].error_name_option = __trans('error_msg.option_value_error') : '');
		setForm({ ...form, type_option: rawdata });
	};

	const handleOptionLabel = (e, index) => {
		const rawdata = [...form.type_option];
		rawdata[index].label = e.target.value;
		rawdata[index].error_name_text = ((e.target.value === '') ? rawdata[index].error_name_text = __trans('error_msg.option_label_error') : '');
		setForm({ ...form, type_option: rawdata });
	};

	const validateForm = () => {
		let newEdit = { ...form };
		let validateData = true;
		if (helpers.isEmpty(form.name)) {
			newEdit.name_error = __trans('error_msg.question_error');
			validateData = false;
		} else {
			newEdit.name_error = '';
		}
		if (form.type === '') {
			newEdit.type_error = __trans('error_msg.question_type_error');
			validateData = false;
		} else {
			newEdit.type_error = '';
		}
		//console.log('validate newEdit', newEdit)
		setForm(newEdit);
		//validate option and label
		if ((form.type === 'checkbox' || form.type === 'radio' || form.type === 'select')) {
			let typeOption = newEdit.type_option;
			const newArrayTypOptn = typeOption.map((item) => {
				item.error_name_option = '';
				item.error_name_text = '';
				
				if (helpers.isEmpty(item.label)) {		
					item.error_name_text =  __trans('error_msg.option_label_error');
					validateData = false;
				}
				
				if (helpers.isEmpty(item.value)) {	
					item.error_name_option = __trans('error_msg.option_value_error');
					validateData = false;
				}
				return item;
			});
			setForm({ ...form, type_option: newArrayTypOptn });
		}
		return validateData;
	}
	
	const handleSubmit = (e) => {
		//Before submit validate form
		if (validateForm()) {
			submitForm({ type: form.type, name: form.name.trim(), type_option: form.type_option, is_visible: false, is_required: false, sort: 0 })
		}
	}

	const submitForm = async (formData) => {
		setSubmitControle({...submitcontrol, status: 'active'})
		var submit_url = id === '0' ? 'question/create' : 'question/update/' + id;
		await makeRequest(submit_url, formData, 'POST', addError)
			.then((res) => {
				dispatch(callCommonAction({ reloaddata: true }));
				setSubmitControle({...submitcontrol, status: 'finished'})
				if (res.type === "success") {
					setForm({ type: '',  type_error: '', name: '',  name_error: '', type_option: [], is_visible: false, is_required: false, sort: 0,});
					//setTimeout(() => {
						setOpen(false)
					//}, 2000);
				} 
			}).catch((error) => {
				setSubmitControle({...submitcontrol, status: 'finished'})
			});
	}
	
	const handleCancel = () => {
		setForm({ type: '',  type_error: '', name: '',  name_error: '', type_option: [], is_visible: false, is_required: false, sort: 0,});
		setOpen(false)
	}
	
    return (
        <Modal className="header-border two-buttons" open={open} onRequestClose={() => handleCancel()} 
			modalHeading={__trans('create_question_page_heading')} 
			modalLabel={id === '0' ? __trans('add_question_text') : __trans('edit_question_text')}
			preventCloseOnClickOutside={true}
			primaryButtonText={__trans('submit_button')} 
			secondaryButtonText={__trans('cancel_button')} 
			onRequestSubmit={() => handleSubmit() }
			loadingStatus={submitcontrol.status} 
			loadingDescription={submitcontrol.description} 
			onLoadingSuccess={resetSubmitControlStatus}
		>
		{!contentLoading ? 
			<Stack gap={5}>
				<Select name="type" id="type" value={form.type}
					labelText={__trans('forms.question_type')} 
					invalid={form.type_error !== '' ? true : false}
					invalidText={form.type_error}
					onChange={valueChanged}
				>
					<SelectItem  disabled value="" text={__trans('forms.question_type_placeholder')} />
					<SelectItem value="text" text="Text" />
					<SelectItem value="number" text="Number" />
					<SelectItem value="select" text="Select" />
					<SelectItem value="checkbox" text="Checkbox" />
					<SelectItem value="radio" text="Radio" />
			   </Select>
				<TextInput type="text" name="name" id="name" 
					value={form.name}
					onChange={valueChanged} 
					labelText={__trans('forms.question_text')} 
					placeholder={__trans('forms.question_text_placeholder')} 
					invalid={form.name_error !== '' ? true : false}
					invalidText={form.name_error}
				/>
			{(form.type === 'checkbox' || form.type === 'radio' || form.type === 'select')	?
				<div className="">
					 <div className="d-flex justify-content-between border-bottom pb-1">
						<p key="feture_input" className='mb-2 bx--label'>{__trans('add_options')}</p>
						<Button size='sm' hasIconOnly renderIcon={Add} iconDescription={__trans('add_button')} onClick={() => incrAndDecrOptn("plus")} />
					 </div>
					 {form.type_option.map((item, index) => {
						return (
							<div className="row pt-1">
								<div className="col-6">
									<TextInput type="text" name="label" id="label" 
										value={item.label || ''}
										onChange={(e) => handleOptionLabel(e, index)}
										labelText={__trans('forms.question_option_label')} 
										placeholder={__trans('forms.question_option_label_placeholder')} 
										invalid={item.error_name_text !== '' &&  item.error_name_text !== undefined ? true : false}
										invalidText={item.error_name_text}
									/>
								</div>
								<div className="col-5">
									<TextInput type="text" name="value" id="value" 
										value={item.value || ''}
										onChange={(e) => handleOptionValue(e, index)}
										labelText={__trans('forms.question_option_value')} 
										placeholder={__trans('forms.question_option_value_placeholder')} 
										invalid={item.error_name_option !== '' && item.error_name_option !== undefined ? true : false}
										invalidText={item.error_name_option}
									/>
								</div>
								<div className="col-1 mt-4">
									<Button kind="danger--tertiary" size='sm' renderIcon={Delete} iconDescription={__trans('delete_button')} hasIconOnly onClick={() => incrAndDecrOptn("minus", item.name, index)} />
								</div>
							</div>							
						);
					 })}
				 </div>
				:
				null
			}
			</Stack>
		:
			<div className='d-flex justify-content-center'>
				<Loading className='text-center' withOverlay={false} />
			</div>
		}
		
      </Modal>
    );
};

export default CreatePlan;
