import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { TableContainer, Table, TableHead, TableHeader, TableBody, TableRow, TableCell, TableSelectRow, 
        TableSelectAll, TableToolbar, TableToolbarContent, TableToolbarSearch, Pagination, OverflowMenu, OverflowMenuItem, ButtonSet, Button, Tag } from '@carbon/react';
import { TrashCan as Delete, Edit, Add, Column, SendAltFilled } from '@carbon/icons-react';		
import { useRowSelection, useSortInfo, useUniqueId } from '../../../_helpers/tables/table_hooks';
import useToastAlert from '../../../hooks/useToastAlert';
import TableLoader from '../../common/TableLoader';
import TableNoData from '../../common/TableNoData';
import { useSelector, useDispatch } from 'react-redux';
import { TABLE_SIZE, TABLE_SORT_DIRECTION } from '../../../_helpers/tables/table_misc';
import { Header as headers } from './Header';
import helpers from '../../../_helpers/common';
import { __trans, getData, makeRequest } from '../../../_helpers';

import DeleteConfirmation from '../../common/DeleteConfirmation';
import TabelColomnSwitch from '../../common/TabelColomnSwitch';
import SendRequestConfirmation from './SendRequestConfirmation';

const hasSelection = true;

const List = () => {
	const { addError } = useToastAlert();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { contentLoading, reloaddata }   = useSelector((state) => state.common);
	const [sendrequest, setSendRequest]    = useState(false);
	const [deleteitem, setDeleteItem]      = useState(false);
	const [selecteditem, setSelectedItem]  = useState('');
	const [searchString, setSearchString]  = useState('');
	const [activeheader, setActiveHeader]  = useState(headers);
	const [rows, setRows]                  = useState([]);
	const [pagination, setPagination]      = useState({page : 1, limit: 15, total_pages: 0, total_item: 0});
	const [headerswitch, setHeaderSwitch]  = useState(false);
	const [sortInfo, setSortInfo]          = useSortInfo({ columnId: '_id', direction: TABLE_SORT_DIRECTION.DESCENDING });
	const [setRowSelection] 			   = useRowSelection(rows, searchString, setRows);
	const selectedRowsCount                = rows.filter(({ selected }) => selected).length;
	const selectedAll                      = selectedRowsCount > 0 && rows.length === selectedRowsCount;
	const hasBatchActions                  = hasSelection && selectedRowsCount > 0;
	const elementId                        = useUniqueId('in_process_list');
	const selectionAllName                 = !hasSelection ? undefined : `__custom-data-table_select-all_${elementId}`;
	
	const { columnId: sortColumnId, direction: sortDirection } = sortInfo;

	const getRequestList = async(type) => {
		var res = {};
		if(type === 'load'){
			res = await getData("firm/request-list", {type: 'in-process', page: pagination.page, limit: pagination.limit, search_string: searchString}, 'POST', addError, dispatch)
		}else{
			res = await makeRequest("firm/request-list", {type: 'in-process', page: pagination.page, limit: pagination.limit, search_string: searchString}, 'POST', addError);
		}
		
		if (res.type === "success") {
			setRows(res.data.docs);
			setPagination({...pagination, total_pages: Number(res.data.totalPages), total_item: Number(res.data.totalDocs)});
		}		
	}

	const handleGetData = useCallback(() => {
		getRequestList('load');
	}, []);
	
	useEffect(() => {
		handleGetData();
	}, [handleGetData])

	useEffect(() => {
		if(reloaddata){
			handleGetData();
		}
	}, [reloaddata])

	useEffect(() => {
		getRequestList('filter');
		//handleGetData();
	}, [pagination.page, pagination.limit, searchString])
	
	const handleDeleteItem = (id) => {
		setSelectedItem(id);
		setDeleteItem(true);
	}

	const handleSendRequest = (id) => {
		setSelectedItem(id);
		setSendRequest(true);
	}

	const handleChangeSearchString = useCallback(({ target }) => {
		setSearchString(target.value);
	}, [setSearchString]);

	const handleChangeSelection = useCallback((event) => {
		const { currentTarget } = event;
		const row = currentTarget.closest('tr');
		if (row) {
			setRowSelection(row.dataset.rowId, currentTarget.checked);
		}
	}, [setRowSelection]);

	const handleChangeSelectionAll = useCallback((event) => {
		setRowSelection(undefined, event.currentTarget.checked);
	}, [setRowSelection]);

	const handleChangeSort = useCallback((event) => {
		const { currentTarget } = event;
		const { columnId, sortCycle, sortDirection: oldDirection, } = currentTarget.dataset;
		setSortInfo({ columnId, sortCycle, oldDirection });
	}, [setSortInfo]);

	/* const handleDeleteRows = useCallback(() => {
		
	}, []); */
	
	const handlePaginationChange = (e) => {
		//console.log('pagination event', e)
		setPagination({...pagination, page: e.page, limit: e.pageSize})
	}

	const processTableCell = (columnId, row, index) => {
		return helpers.processRequestData(row, columnId, TableCell, Tag);
	}

  /* eslint-disable no-script-url */
  return (
    <>
	{!contentLoading ?
    <TableContainer title={__trans('in_process_client_list_title')} description={__trans('in_process_client_list_info')}>
      <TableToolbar size="lg">
        <TableToolbarContent className="align-items-center">
          <TableToolbarSearch
            value={searchString}
				defaultExpanded={searchString.length > 0 ?? true}
				tabIndex={hasBatchActions ? -1 : 0}
				onChange={handleChangeSearchString}
          />
		  <Button size="sm" kind="ghost" hasIconOnly renderIcon={Delete} iconDescription={__trans('delete_button')} onClick={() => alert('TableToolbarAction - Delete')} />
		  <Button size="sm" kind="ghost" hasIconOnly renderIcon={Column} iconDescription={__trans('colomn_configration_button')} onClick={() => alert('Colomn')} />
		  <Button size="sm" kind="ghost" hasIconOnly renderIcon={Add} iconDescription={__trans('add_button')} onClick={() => alert('Add')} />
		  
		  <ButtonSet className="pe-0">
			  <Button className="btn--primary" size="lg" onClick={() => navigate('/clients/new-request/0')} kind="primary" renderIcon={Add}>
				{__trans('new_request_button')}
			  </Button>
		  </ButtonSet>
        </TableToolbarContent>
      </TableToolbar>
      <Table size="sm" isSortable useZebraStyles={true}>
        <TableHead>
          <TableRow>
            {hasSelection && (
              <TableSelectAll
                id={`${elementId}--select-all`}
                checked={selectedAll}
                indeterminate={
                  selectedRowsCount > 0 && !selectedAll
                }
                ariaLabel="Select all rows"
                name={selectionAllName}
                onSelect={handleChangeSelectionAll}
              />
            )}
            {activeheader.filter((header) => header.active).map(({ id: columnId, sortCycle, title }) => {
              const sortDirectionForThisCell = sortCycle && (columnId === sortColumnId ? sortDirection : TABLE_SORT_DIRECTION.NONE);
              return (
                <TableHeader
                  key={columnId}
                  isSortable={Boolean(sortCycle)}
                  isSortHeader={sortCycle && columnId === sortColumnId}
                  sortDirection={sortDirectionForThisCell}
                  data-column-id={columnId}
                  data-sort-cycle={sortCycle}
                  data-sort-direction={sortDirectionForThisCell}
                  onClick={handleChangeSort}>
                  {title}
                </TableHeader>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ?
			rows.map((row, index) => {
				const { id: rowId, selected } = row;
				const selectionName = !hasSelection ? undefined : `__custom-data-table_${elementId}_${rowId}`;
				return (
				  <TableRow
					key={rowId}
					isSelected={hasSelection && selected}
					data-row-id={rowId}>
					{hasSelection && (
					  <TableSelectRow
						id={`${elementId}--select-${rowId}`}
						checked={Boolean(selected)}
						name={selectionName}
						ariaLabel="Select row"
						onSelect={handleChangeSelection}
					  />
					)}
					{activeheader.filter((header) => header.active).map(({ id: columnId }) => (
					  <>
						{columnId !== 'actions' ?
						  <>
						  {processTableCell(columnId, row, index)}
						  </>
						  :
							<TableCell key={columnId}>
								<div className="d-flex align-items-center">
									<Button size="sm" kind="ghost" renderIcon={SendAltFilled} onClick={() => handleSendRequest(row._id)} iconDescription={__trans('send_invite_button')} hasIconOnly />
									<Button size="sm" kind="ghost" renderIcon={Edit} onClick={() => navigate('/clients/new-request/' + row._id)} iconDescription={__trans('edit_button')} hasIconOnly />
									<Button size="sm" kind="ghost" renderIcon={Delete} onClick={() => handleDeleteItem(row._id)} iconDescription={__trans('delete_button')} hasIconOnly />
									<OverflowMenu size="sm" flipped>
										<OverflowMenuItem itemText="Report a Problem" />
									</OverflowMenu>
								</div>
							</TableCell>
						}
					  </>
					))}
				  </TableRow>
				);
			  })
			:
			<TableNoData headers={activeheader}/>
		  }
        </TableBody>
      </Table>
      <Pagination
		  backwardText="Previous page"
		  forwardText="Next page"
		  itemsPerPageText="Items per page:"
		  onChange={(e) => handlePaginationChange(e)}
		  page={pagination.page}
		  pageSize={pagination.limit}
          pageSizes={[5, 10, 15]}
		  size="md"
		  totalItems={pagination.total_item}
		/>
    </TableContainer>
	:
		<TableLoader {...helpers.tableLoaderProps(activeheader)}/>
	}
	{/* Delete Confirmation popup */}
	<DeleteConfirmation open={deleteitem} setOpen={setDeleteItem} module='client-request' id={selecteditem}/>
	{/* Send Request Confirmation popup */}
	<SendRequestConfirmation open={sendrequest} setOpen={setSendRequest} id={selecteditem}/>
	{/* Table colomn Switch popup */}
	<TabelColomnSwitch open={headerswitch} setOpen={setHeaderSwitch} activeheader={activeheader} setActiveHeader={setActiveHeader}/>
    </>
  );
};

List.defaultProps = {
  collator: new Intl.Collator(),
  hasSelection: false,
  pageSize: 5,
  size: TABLE_SIZE.REGULAR,
  start: 0,
};

export default List;
