import { __trans } from '../../../_helpers'

export const Header = [
  {
    id: 'expand',
    title: '',
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'name',
    title: __trans('uploaded_document_table_header.name'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'company',
    title: __trans('uploaded_document_table_header.company'),
    //sortCycle: 'bi-states-from-ascending',
  },
   {
    id: 'type',
    title: __trans('uploaded_document_table_header.type'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'size',
    title: __trans('uploaded_document_table_header.size'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'recived_date',
    title: __trans('uploaded_document_table_header.recived_date'),
    //sortCycle: 'bi-states-from-ascending',
  },
  {
    id: 'actions',
    title: __trans('uploaded_document_table_header.actions')
  },
  
];