import React, { useRef , useState } from 'react';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Modal, Form, Stack, TextInput, TextArea, DatePicker, DatePickerInput, Dropdown, Checkbox, Toggle, Loading } from '@carbon/react'; 
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import useToastAlert from '../../../hooks/useToastAlert';
import helpers from '../../../_helpers/common';
import { __trans, makeRequest } from '../../../_helpers';

const items = [
        { text: `1 ${__trans('forms.day_label')}`,  id: 1 },
        { text: `2 ${__trans('forms.days_label')}`, id: 2 },
        { text: `3 ${__trans('forms.days_label')}`, id: 3 },
        { text: `4 ${__trans('forms.days_label')}`, id: 4 },
    ];

const ReviewDelivery = ({ open, setOpen, form, setForm, setNextStep }) => {
	const textAreaRef = useRef(null);
	const { addError } = useToastAlert();
	const navigate = useNavigate();
	const { contentLoading } = useSelector((state) => state.common);
    const dispatch = useDispatch();
	const [submitcontrol, setSubmitControle] = useState({ status: 'inactive', description: __trans('submit_button') });
	
	const resetSubmitControlStatus = () => {
		setSubmitControle({...submitcontrol, status: 'inactive', description: __trans('submit_button')})
	};
	
	const handleDueDaysChange = (e) => {
		//console.log('valueChanged e', e)
		const due_day = e.selectedItem.id;
		//console.log('valueChanged due_day', due_day)
		setForm({...form, reminder_before_days: due_day});
	};
	
	const toggleButtonClicked = (name, value) => {
		let newEdit = { ...form };
		newEdit[name] = value;
		setForm(newEdit);
	}
	
	const expireDateChanged = (value) => {
		let selected_date = value[0];
		//console.log('valueChanged selected_date', selected_date)
		let formated_date = moment(selected_date).format("MM/DD/YYYY");
		setForm({...form, expire_at: formated_date});
		//console.log('valueChanged formated_date', formated_date)
	}
	
	const valueChanged = (e) => {
		const { name, value } = e.target;
		let newEdit = { ...form };
		newEdit[name] = value;
		setForm(newEdit);
	}
	
	const checkIsChecked = (e) => {
		if(e.target.checked){
			setForm({...form, send_reminder: true});
		}else{
			setForm({...form, send_reminder: false, expire_at_error: ''});
		}
	}
	
	const validateForm = () => {
		let newEdit = { ...form };
		let validateData = true;
		if (form.email === '') {
			newEdit.email_error = __trans('error_msg.email_error');
			validateData = false;
		} else {
			newEdit.email_error = '';
			if (!helpers.isValidEmail(form.email)) {
				newEdit.email_error = __trans('error_msg.email_invalid');
				validateData = false;
			}
		}
		
		if(form.send_reminder){
			if(form.expire_at === '' || form.expire_at === null){
				newEdit.expire_at_error = __trans('error_msg.expiry_date_error');
				validateData = false;
			}
		}else{
			newEdit.expire_at_error = '';
		}
		
		setForm(newEdit);
		return validateData;
	}
	
	const handleSubmit = (type) => {
		//console.log('handle submit form data', form)
		//Before submit validate form
		if (validateForm()) {
			let sendrequest = type === 'send' ? 1 : 0;
			submitForm({ package_id: form.package_id, client_id: form.client_id, sign_document: form.sign_document, question_template_id: form.question_template_id, documents_template_id: form.documents_template_id, email: form.email, message_to_client: form.message_to_client, expire_at: form.expire_at, notification_on_task: form.notification_on_task, notification_on_all: form.notification_on_all, send_reminder: form.send_reminder, reminder_before_days: form.reminder_before_days, send_request: sendrequest});
		}
	}

	const submitForm = async (formData) => {
		//console.log('formData', formData)
		setSubmitControle({...submitcontrol, status: 'active'})
		let end_url = form.client_request_id === '' ? 'firm/create-client-request' : 'firm/update-client-request/'+form.client_request_id;
		await makeRequest(end_url, formData, 'POST', addError)
			.then((res) => {
				dispatch(callCommonAction({ reloaddata: true }));
				setSubmitControle({...submitcontrol, status: 'finished'})
				if (res.type === "success") {
					if(formData.send_request === 1){
						navigate('/clients/delivered-list');
					}else{
						navigate('/clients/in-progress-list');
					}
				} 
			}).catch((error) => {
				setSubmitControle({...submitcontrol, status: 'finished'})
			});
	}
	
	const checkIntialDaySelected = () => {
		const current_item = items.filter((item) => item.id === form.reminder_before_days);
		if(current_item.length > 0){
			return current_item[0];
		}else{
			return items[1];
		}
		
	}
	
	const handleCancel = () => {
		navigate('/clients/in-progress-list');
	}
	
	//console.log('formdata', formdata)
	return (
		<Modal className="header-border two-buttons" open={open} onRequestClose={() => handleCancel()} 
			modalHeading={__trans('review_and_delivery_title')} 
			modalLabel=""
			preventCloseOnClickOutside={true}
			primaryButtonText={__trans('send_button')} 
			secondaryButtonText={__trans('save_and_close_button')} 
			onSecondarySubmit={() => handleSubmit('save_close')}
			onRequestSubmit={() => handleSubmit('send') }
			loadingStatus={submitcontrol.status} 
			loadingDescription={submitcontrol.description} 
			onLoadingSuccess={resetSubmitControlStatus}
		>
		{!contentLoading ?
		
			<Form aria-label="create/update client form">
				<Stack>
					<div className=''>
						<p className="cds--label-description fs-16 text-16">
							{__trans('review_and_delivery_info')}
						</p>
						<div className="row mb-2">
							<div className="col-md-6">							
								<TextInput className="mb-3" type="email" name="email" id="email" 
									value={form.email}
									onChange={valueChanged}
									labelText={__trans('forms.email_label')}
									placeholder={__trans('forms.email_placeholder')}
									invalid={form.email_error !== '' ? true : false}
									invalidText={form.email_error}
								/>
								<DatePicker className="mb-3"
								  datePickerType="single"
								  onChange={expireDateChanged}
								  onClose={function noRefCheck(){}}
								  onOpen={function noRefCheck(){}}
								>
								  <DatePickerInput name="expire_at"	id="expire_at"
									value={form.expire_at}
									labelText={__trans('forms.new_request_expiry_date_label')}
									onChange={expireDateChanged}
									onClose={function noRefCheck(){}}
									onOpen={function noRefCheck(){}}
									placeholder="mm/dd/yyyy"
									invalid={form.expire_at_error !== '' ? true : false}
									invalidText={form.expire_at_error}
								  />
								</DatePicker>
								<div className="mb-3">
									<span className="cds--label">{__trans('forms.new_request_notification_label')}</span>
									<div>
										<Toggle size="sm" name="notification_on_task" id="notification_on_task" onToggle={(e) => toggleButtonClicked('notification_on_task', e)} labelText="" labelA={__trans('forms.new_request_one_task_notification_label')} labelB={__trans('forms.new_request_one_task_notification_label')} defaultToggled={form.notification_on_task === 1 ? true : false}/>
									</div>
									<div>
										<Toggle size="sm" name="notification_on_all" id="notification_on_all" onToggle={(e) => toggleButtonClicked('notification_on_all', e)} labelText="" labelA={__trans('forms.new_request_all_task_notification_label')} labelB={__trans('forms.new_request_all_task_notification_label')} defaultToggled={form.notification_on_all === 1 ? true : false}/>
									</div>
								</div>
								
								<div className="">
									<span className="cds--label">{__trans('forms.new_request_reminder_label')}</span>
									<div className="d-flex align-items-center">
										<div>
											<Checkbox onChange={(e) => checkIsChecked(e)} defaultChecked={form.send_reminder === 1 ? true : false} labelText={``} id="send_reminder" />
										</div>
										<Dropdown className="small-dropdown" size="sm" id="reminder_days" direction="top"
											onChange={handleDueDaysChange}
											titleText=""
											label={checkIntialDaySelected().text}
											initialSelectedItem={checkIntialDaySelected()}
											items={items}
											itemToString={item => (item ? item.text : '')}
										/>
										<p className="ps-1">{__trans('forms.new_request_due_day_label')}</p>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div>
								   <TextArea name="message_to_client" id="message_to_client"
									value={form.message_to_client}
									onChange={valueChanged}
									ref={textAreaRef}
                                    labelText={__trans('forms.new_request_message_to_client_label')}
                                    placeholder={__trans('forms.new_request_message_to_client_placeholder')}
                                    rows={13}
                                />
								</div>
							</div>
						</div>
                    </div>
				</Stack>
			</Form>
			:
			<div className='d-flex justify-content-center'>
				<Loading className='text-center' withOverlay={false} />
			</div>
		}
	</Modal>
	)
}

export default ReviewDelivery
