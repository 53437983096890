import React, {useEffect, useState} from 'react';
import { Form, TextInput, Button, InlineLoading } from '@carbon/react';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { callCommonAction } from '../../../redux/Common/CommonReducer';
import AuthLayout from "../../layout/AuthLayout";
import useToastAlert from '../../../hooks/useToastAlert';
import helpers from '../../../_helpers/common'
import { __trans, makeRequest, checkUserLoggedIn, userDetails } from '../../../_helpers'

const ForgotPassword = () => {
	const { addError } = useToastAlert();
	const { loading } = useSelector((state) => state.common);
    const dispatch = useDispatch();
	const navigate = useNavigate();
	const [form, setForm] = useState({ email: '',  email_error: '' });

	useEffect(() => {
		//Check if user token exist then redirect him to dashboard
		checkUserIsLoggedIn();
	})

	const checkUserIsLoggedIn = () => {
		if(checkUserLoggedIn()){
			if(userDetails('role') !== 'Admin'){
				navigate('/clients/in-progress-list');
			}else{
				navigate('/dashboard');
			}	
		}
	}

	const valueChanged = (e) => {
		const { name, value } = e.target;
		let newEdit = { ...form };
		newEdit[name] = value;
		newEdit[name + '_error'] = '';
		setForm(newEdit);
	}

	const validateForm = () => {
		let newEdit = { ...form };
		let validateData = true;
		
		if (helpers.isEmpty(form.email)) {			
			newEdit.email_error = __trans('error_msg.email_error');
			validateData = false;
		} else {
			newEdit.email_error = '';
			if (!helpers.isValidEmail(form.email)) {
				newEdit.email_error = __trans('error_msg.email_invalid');
				validateData = false;
			}
		}
		setForm(newEdit);
		return validateData;
	}
	const handleSubmit = (e) => {
		//Before submit validate form
		if (validateForm()) {
			submitForm({ email: form.email })
		}
	}

	const submitForm = async (formData) => {
		dispatch(callCommonAction({ loading: true }));
		await makeRequest("users/forgot-password", formData, 'POST', addError)
			.then((res) => {
				dispatch(callCommonAction({ loading: false }));
				if (res.type === "success") {
					
				}
			}).catch((error) => {
				dispatch(callCommonAction({ loading: false }));
			});
	}
	return (
		<AuthLayout>
			<Form className="signup-form mb-3">
				<div className="form-welcome-heading">
					<h2 className="mb-4">{__trans('forgot_page_heading')}</h2>
				</div>
				<div className="form-heading mb-5 pt-3">
					<h3 className="mb-3">{__trans('forgot_title')}</h3>
					<p>{__trans('forgot_text')} </p>
				</div>
				<div className="mb-4">
					<TextInput type="email" name="email" id="email" onChange={valueChanged} 
						labelText={__trans('forms.email_label')} 
						placeholder={__trans('forms.email_placeholder')}
						invalid={form.email_error !== '' ? true : false}
						invalidText={form.email_error}
					/>
				</div>
				<div className="mt-4 mb-4">
					{!loading ? 
						<Button className="btn btn-primary" onClick={handleSubmit} size="md">{__trans('forms.forgot_submit_button')}</Button>
						:
						<Button kind="secondary" size="md" as="div" role="button">
							<InlineLoading className="text-info" style={{ marginLeft: '1rem' }} description="Wait..." status="active" aria-live="off"/>
						</Button>
					}	
				</div>
				<div className=" text-end">
					<Link to="/login" className="text-decoration-none fs-14 text-primary">{__trans('login_link')}</Link>
				</div>
			</Form>
		</AuthLayout>
	)
}

export default ForgotPassword
