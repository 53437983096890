const countries = require('../../country/countriesminified.json');
const states    = require('../../country/statesminified.json');
const cities    = require('../../country/citiesminified.json');


export const GetCountries = async () => {
	return countries;
};

export const GetState = async (id) => {
	const statesone = states.find((item) => item.id === Number(id));
	return statesone && statesone.states ? statesone.states : [];
};

export const GetCity = async (countryid, stateid) => {
	//console.log('countryid', countryid)
	//console.log('stateid', stateid)
	const countries = cities.find((item) => item.id === Number(countryid));
	//console.log('countries', countries)
	if (countries) {
		const states = countries && countries.states ? countries.states : [];
		const city = states.find((e) => e.id === Number(stateid));
		return city && city.cities ? city.cities : [];
	} else {
		return [];
	}
};

export function countryCodeEmoji(cc) {
	cc = 'AE';
	// country code regex
	const CC_REGEX = /^[a-z]{2}$/i;
	// flag emoji use 2 regional indicator symbols, and each symbol is 2 chars
	const FLAG_LENGTH = 4;

	// offset between uppercase ascii and regional indicator symbols
	const OFFSET = 127397;

  if (!CC_REGEX.test(cc)) {
    const type = typeof cc;
    throw new TypeError(
      `cc argument must be an ISO 3166-1 alpha-2 string, but got '${
        type === 'string' ? cc : type
      }' instead.`,
    );
  }

  const codePoints = [...cc.toUpperCase()].map(c => c.codePointAt() + OFFSET);
  return String.fromCodePoint(...codePoints);
}