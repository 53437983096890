import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Tile, Tag } from '@carbon/react';
import useToastAlert from '../../../hooks/useToastAlert';
import { __trans, makeRequest } from '../../../_helpers'

const Dashboard = () => {
	//console.log('comming to admin dashboard')
	const { addError } = useToastAlert();
	const [dashboarddata, setDashboardData] = useState({firm_count: 0, contact_us_count: 0});
	
	const getDashboardData = async() => {
		//console.log('getDashboardData function called')
		await makeRequest("dashboard/get-dasboard-data", {}, 'POST', addError)
			.then((res) => {
				if (res.type === "success") {
					setDashboardData({ ...dashboarddata, firm_count: res.firm_count, contact_us_count: res.contact_us_count});
				} 
			}).catch((error) => {
				
			});
	}
  
	useEffect(() => {
		//console.log('dashboard use effect')
		getDashboardData();
	}, [])
	
	return (
		<>
			<div className="page-heading">
				<h1>{__trans('dashboard.welcome_tile')}</h1>
			</div>
			<div className="row">
				<div className="col-3">
					<Tile id="tile-1">
						<div className="d-flex justify-content-between">
							{__trans('dashboard.firm_count')}
							<Tag className="" type='blue'>
								{dashboarddata.firm_count}
							</Tag>
						</div>
						<br />
						<br />
						<Link to="/firm-list">{__trans('dashboard.see_all_link')}</Link>
					</Tile>	
				</div>
				<div className="col-3">
					<Tile id="tile-1">
						<div className="d-flex justify-content-between">
							{__trans('dashboard.contact_us_count')}
							<Tag className="" type='blue'>
								{dashboarddata.contact_us_count}
							</Tag>
						</div>
						<br />
						<br />
						<Link to="/contactus-list">{__trans('dashboard.see_all_link')}</Link>
					</Tile>	
				</div>
			</div>
	  </>
	)
}

export default Dashboard
